.ForgotPassword_Wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 5rem 1rem 1rem;
  flex-wrap: wrap;
  background: url("../../assets/icons/svg/constants/credentialsBg.svg") no-repeat fixed;
  background-position-x: center;
  background-position-y: center;
  background-size: 100%;
 .contact-us-wrapper{
    margin-inline-end: auto;
    align-self: baseline;
    .contact-us{
      background: $secondary_layer;
      &:hover {
          background-color: $primary-txt;
      }
    }
 }
  .password {
    .input-group-prepend {
      margin-top: 0 !important;
    }
  }

  .form-view {
    padding: 30px 4rem 0px 4rem;
    background-image: linear-gradient(#37aca0, #09635a, #03403a);
    border-radius: 2%;
    height: 90vh;
    display: flex;
    width: 400px;
    flex-direction: column;
    box-shadow: -6px 5px 25px #221e1e;

    .message-error {
      color: #F48585;
    }

    .proceed-button {
      margin-inline: 2rem !important;
    }

    .inputs-container {
      height: 68vh;
    }

    .Issues-Reminder-Message-Wrapper {
      .input-group-prepend {
        white-space: nowrap;
        vertical-align: middle;
        margin-top: 0.1rem;
      }
    }


  }

  .reset-form {
    .proceed-button {
      margin-top: auto;
      margin-bottom: 5em;
    }
  }

  .logo {
    justify-content: center;
    display: flex;
    margin-bottom: 40px;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  .btn.btn-primary {
    background: var(--cta-primary-normal-state-sub-txt-on-config-bg) 0% 0% no-repeat padding-box;
    background: #73e4cc 0% 0% no-repeat padding-box;
    border-radius: 5px;

    label {
      width: 83%;
      cursor: pointer;
      margin: unset;
      font-size: 13px !important;
    }

    .CTA-AR:disabled:after {
      background: url("../../assets/icons/svg/proceed.svg") no-repeat center !important;
    }

    .CTA-AR::after {
      background: $button-after-bg url("../../assets/icons/svg/proceed.svg") no-repeat center !important;
    }
  }

  .btn.btn-primary::after {
    content: " ";
    width: 15%;
    height: 100%;
    margin-left: 2%;
    border-radius: 30px;
    background: 'url(/static/media/CTA_Arrow.97625f1a.svg)' no-repeat center;
    cursor: pointer;
  }

  .Login_Back {
    align-self: flex-start;
    margin: 0px;
    margin-inline-start: -15%;
    margin-block-start: 25px;

    &:hover {
      cursor: pointer;
      color: #73E4CC;
    }
  }
}

[dir='rtl'] .ForgotPassword_Wrapper {
  padding: 1rem 1rem 1rem 5rem;
  background: url("../../assets/icons/svg/constants/credentialsBgAr.png") no-repeat fixed;
  background-position-x: center;
  background-position-y: center;
  background-size: 100%;
}

@media (max-width: 500px) {
  .Credentials_Wrapper {
    padding: 20px;

    .form-view {
      width: 100% !important;
    }
  }

}