.Add-New-Farm {
    background: url("../../assets/icons/svg/AssociatedFarms/NF_bg.svg") no-repeat fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    max-width: 120% !important;
    padding: 0 !important;
    justify-content: end;
    min-height: 100vh;

    .container-logo {
        svg {
            margin-top: 2em;
        }
    }

    .bg-container {
        display: flex;
        flex: 3;
        margin-inline-start: 5.7em;
        height: 83vh;
        border-bottom-left-radius: 1%;
        border-top-left-radius: 1%;
        margin-top: 8.5em;
        position: relative;
    }

    .content-container {
        display: flex;
        height: 100%;
        width: 100%;
    }

    .no-farms-list-container {
        display: flex;
        background-color: $main_layer;
        flex-direction: column;
        align-items: center;
        text-align: center;
        min-height: 100vh;
        margin-inline-end: 5.7em;
        box-shadow: -1px 0 25px rgba(0, 18, 18, 0.78);

        .no-farms-buttons-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .lang-logout-buttons {
            display: flex;
            justify-content: end;
            width: 100%;
            .user-farms-avatar-dropdown{
                padding-top: 24px;
                .avatar-dropdown-component.c-header-nav-items:has(#avatar-button) .dropdown-menu.show {
                    width: 12rem !important;
                }
                .avatar-dropdown-component.c-header-nav-items:has(#avatar-button) .dropdown-menu::before{
                    left: 43% !important;
                }
            }
        }

        .new-farm-text {
            background: #073A36 0% 0% no-repeat padding-box;
            border: 1px solid #19B39266;
            border-radius: 10px;
            opacity: 1;
            margin-block-start: 2rem;
            height: 79%;
            margin-block-end: 1.7rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 90%
        }

        h3 {
            @extend .heading;
            margin-bottom: -1vh;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)));
        }

        #user-farm-subtitle {
            display: block;
            @extend .link;
            padding: 0 2.2vw;
            margin-block-start: 0;
            margin-block-end: 2em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            color: #73E4CC;
            opacity: 0.7;
            max-width: 100%;
        }

        .proceed-button {
            opacity: 1;
            width: fit-content;
            max-width: 90%;
            margin-inline: auto;
        }

        .change-org {
            @extend .logout-button;
            margin-inline-start: 1.4em;

            svg {
                width: 0.6em !important;
                display: block;
                margin-inline: auto !important;
            }

            &:hover svg path {
                stroke: #15675F;
            }
        }
    }

    @media (min-width:2000px) {
        .list-container {
            flex: 1.5;
        }

        .bg-container {
            flex: 3;
        }
    }

    // Large desktop screens
    @media (min-width:1300px) and (max-width:2000px) {
        .no-farms-list-container {
            flex: 1.5;
        }

        .bg-container {
            flex: 3;
        }
    }

    @media (min-width:1920px) {
        .bg-container {
            z-index: -1;
        }

        .no-farms-list-container {
            box-shadow: -2px 0 30px rgba(1, 32, 32, 0.66);
            max-width: 30%;
        }
    }

    // Small screen laptops
    @media (min-width:850px) and (max-width:1300px) {
        .no-farms-list-container {
            flex: 2;
        }

        .bg-container {
            flex: 3;
        }
    }

    @media (max-width: 850px) {
        background: none;

        .content-container {
            justify-content: center;
        }

        .no-farms-list-container {
            flex: 0.7;
            margin: 0;
        }

        .bg-container {
            display: none;
        }
    }
}

[dir='rtl'] .Add-New-Farm {
    background: url("../../assets/icons/svg/AssociatedFarms/NF_bg_flipped.png") no-repeat fixed;
    background-position: center;
}

@media (max-width: 850px) {
    [dir='rtl'] .Add-New-Farm {
        background: none;

        .content-container {
            justify-content: center;
        }

        .no-farms-list-container {
            flex: 0.7;
            margin: 0;
        }

        .bg-container {
            display: none;
        }
    }

    [dir='rtl'] .container-logo {
        display: none;
    }

    .container-logo {
        display: none;
    }
}