.CropPhase-Card{
    height: 80em !important;
}


.crop-phase-card-container{
    min-height:50em;
    .CropPhase-Container{
        margin-top: 1em;
    }
}
.CropViewDistribution{
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 100%;
    border: inherit;
    justify-content: 'flex-start';align-items:flex-start;
 
} 


.FarmCropDetailsDis{
    flex:2;
     height: 100%;
     flex-direction: row;
     width: 100%;
    flex-wrap: wrap;
     .Card{
        margin-top: 10px;
        width: 100%;
       
        display: flex;
        overflow-x: scroll;
        ul{
            list-style-type: none;
            display: flex;
             width: 100%;
            // flex-wrap: wrap;
            margin-inline-start: -25px;
            margin-top: 2em
        }
        li{
            margin-inline-end: 30px;
        }
        .card-with-icon-container{
            display: flex;
            flex-wrap:wrap;
            position:relative;
            width:15em;
            height: 18em;
            padding-bottom:1em;
            border-radius:5px;
            border:1px solid #003A35;
            background-color:#003A35;

            .icon-container{
                left:5.5em;
                top:-1.7em;
                position:absolute;
                width:3.5em;
                height:3.5em;
                border-radius:1.75em;
                background-repeat: no-repeat;
                background-size: 2em 2em;
                background-position: center center;
                border: 1px solid $secondary-txt;
            }
        }
        .Header{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #C4FFF9;
            text-transform: uppercase;
            font-weight: bolder;
            font-size: 0.8em;
            margin-top: 34px;
            margin-inline-start: 31%;

           

        }
        .Header-Seperator{
            border-top: 1px solid;
            width: 100%;
            opacity: 0.3;
            margin-inline-start: 20px;
            margin-inline-end: 20px;
            margin-bottom: 9px;
        }
        .Content{
            flex-wrap: wrap;
            overflow: scroll;   
            height: 70%;
            width: 100%;
            margin-inline-start:20px ;
            .empty-data-state-container {
                height: 40em ;
            }
            p{
                font-size: 0.7em;
                margin-inline-end: 10px;
            }
        }
       }
}

.FarmDetailsDis{
    flex:1;
    height: 100%;
    width: 100%;
    .Content{
        display: flex;       
        height: 100%;
        justify-content: space-evenly;
        flex-direction: row;
        .Chart-View{
          flex:2;
          margin-top: 20px;
          margin-inline-start: 42px;
         .Title{
             margin-inline-start: -20px;
             margin-bottom: 30px;
             display: flex;
              height:10%;
              width: 100%;
              flex-direction: row;
             justify-content: space-evenly;

             .Plots{

               text-align: center;
             }
             .Plots-Count{
                text-align: center;
                .number{
                    color: $primary_txt;
                    font-weight: bold;
                    font-size: 2.3rem;
                    color: #C4FFF9;
                    margin-top: -16px;
                    font-weight: bold;
                }
                .Text{
                    margin-top: -25px;
                    font-size: 0.6rem;
                }
             }

             .Types{
             
                text-align: center;

             }
             .Types-Count{
                text-align: center;
                .number{
                    font-size: 2.3rem;
                    color: #C4FFF9;
                    margin-top: -16px;
                    font-weight: bold;
                
                }
                .Text{
                    margin-top: -25px;
                    font-size: 0.6rem;
                }
             }

             .Seperator{
               border-right: 1px  solid  ;
               opacity: 0.3;
               margin-inline-start:20px ;
               margin-inline-end:20px ;

             }
         }
        }
        .DistributionSeperator{
            flex-wrap: wrap;
            opacity: 0.3;
            flex:0.2;
           border-right: 2px  dashed  ;
           height:370px ;
           
            }
    }
}

.CropPhase-Container{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex: 1;

    .TimeLine{
        display: flex;
        flex:0.8;
        margin-bottom: 10px;
        background-color: #003A35;
        flex-direction: row;
        width: 100%;
        height: 100%;
    .Container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex: 0.8;
        margin-top: -12px;
        
        .Crop-Image-View{
            width:310px;
            height:300px;
            border-radius: 10px;
            margin-top: -12px;
            margin-inline-start: 2em;
            margin-inline-end: 1em;
            position: relative;
        }
        [dir=rtl].Line{
            // display: flex;
            // margin-top: -15%;
            // align-items: center;
            // justify-content: center;
            // flex-direction: row;
            // flex-wrap: wrap;
            // left: -12px;
            bottom: -40px;
            right: -20px;
            bottom:-40px;
            position: absolute;
    // position: relative;
            .Scroll-View{
                width: 100%;
                min-width: 350px;
                // max-width: 100%;
                overflow-y: scroll;
                display: flex;
                li{
                    min-height: 0;
                }
            }
        .List-Container{
            display: flex;
            .End-Date-View{
                height: 100%;
                [dir=rtl].Circle{
                    border: 2.5px solid;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    height: 30%;
                    width: 23px;
                    border-radius: 30px;
                    margin-right: -68%;
                }
            }
            ul{
                list-style-type: none;
                display: flex;
                height: 100%;
                margin-inline-start: -40px;
            
              
            }
        
            p{
                font-size: 0.7em;
                font-weight: bold;
            }
      
        //    li{
        //        display: flex;
        //        text-align: center;
        //    }
        
    }
     [dir=rtl].List-Container{
        display: flex;
    margin-right: -4%;
    }
         .Header{
            height: 9px;
            width: 40px;
            border: 2px solid #65AAA4;
            border-right-width: 0px;
            border-radius: 10px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-top: 7px;
            margin-left: 3%;
            background-color: #65AAA4;
        
            .content{
                border-top-width: 1px;
                width: 37px;
                align-items: stretch;
                display: flex;
                background-color: $primary_txt;
                // height: -7px;
                border-top-style: dashed;
              
            }
    
            .Icon-Style{
                width: 15%;
                height: 7px;
                margin-inline-start: 2px;
                background-color: #03403A;
                border-radius: 10px;
                border: 2.5px solid #14766B;
            }
         }
        
         .Circle-Wrap{
             display: flex;
             flex-direction: column;
             align-items: center;
             justify-content: center;
             text-align: center;
         }    
         
         .Circle{  
            border: 2.5px solid;
            text-align: center;
            align-items: center;
            justify-content: center;
            height: 30%;
            width: 23px;
            border-radius: 30px;
            margin-left: -23%;
    
        }
        [dir=rtl].Circle{  
            border: 2.5px solid;
            text-align: center;
            align-items: center;
            justify-content: center;
            height: 30%;
            width: 23px;
            border-radius: 30px;
            margin-right: -20%;
    
        }
        .Seperator-Wrapper{
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            [dir=ltr].Seperator{
                height: 13%;
                width: 86%;
                border: 2px solid #04403B;
                border-left-width: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 14.1%;
                margin-left: -12.2%;
            
                .content{
                    border-top-width: 1px;
                    width: 37px;
                    align-items: stretch;
                    display: flex;
                   
                    // height: -7px;
                    border-top-style: dashed;
                  
                }
        
            }

            [dir=rtl].Seperator{
                height: 13%;
                width: 86%;
                border: 2px solid #04403B;
                border-left-width: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 14.1%;
                margin-right: -13.2%;
            
                .content{
                    border-top-width: 1px;
                    width: 37px;
                    align-items: stretch;
                    display: flex;
                   
                    // height: -7px;
                    border-top-style: dashed;
                  
                }
        
            }
        }
        [dir=rtl].Footer{
                  
            .Seperator{
                flex-direction: row;
                height: 13%;
                width: 40px;
                border: 2px solid #04403B;
                border-radius: 10px;
                border-left-width: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 14.1%;
                margin-right: -42.2%;
            
                .content{
                    border-top-width: 1px;
                    width: 37px;
                    align-items: stretch;
                    display: flex;
                   
                    // height: -7px;
                    border-top-style: dashed;
                  
                }
                .Icon-Style{
                    width: 20%;
                    height: 7px;
                    margin-inline-start: 2px;
                    background-color: #03403A;
                    border-radius: 10px;
                    border: 2.5px solid #14766B;
                }
            }
         }
    
        [dir=ltr].Footer{
                  
            .Seperator{
                flex-direction: row;
                height: 13%;
                width: 86%;
                border: 2px solid #04403B;
                border-radius: 10px;
                border-left-width: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 14.1%;
                margin-left: -8.2%;
            
                .content{
                    border-top-width: 1px;
                    width: 37px;
                    align-items: stretch;
                    display: flex;
                   
                    // height: -7px;
                    border-top-style: dashed;
                  
                }
                .Icon-Style{
                    width: 20%;
                    height: 7px;
                    margin-inline-start: 2px;
                    background-color: #03403A;
                    border-radius: 10px;
                    border: 2.5px solid #14766B;
                }
            }
         }
        }
        [dir=ltr].Line{
            // display: flex;
            // margin-top: -15%;
            // align-items: center;
            // justify-content: center;
            // flex-direction: row;
            // flex-wrap: wrap;
            // width: 100%;
            // height: 100%;
            /* top: -7px; */
            position: absolute;
            // position: relative;
            // top: -1%;
            bottom: -40px;
            left: -35px;
            .Scroll-View{
                width: 100%;
                min-width: 350px;
                // max-width: 100%;
                overflow-y: scroll;
                display: flex;
            }
        .List-Container{
            display: flex;
            .End-Date-View{
                height: 100%;
                [dir=rtl].Circle{
                    border: 2.5px solid;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    height: 30%;
                    width: 23px;
                    border-radius: 30px;
                    margin-right: -68%;
                }
            }
            ul{
                list-style-type: none;
                display: flex;
                height: 100%;
                margin-inline-start: -40px;
            
              
            }
        
            p{
                font-size: 0.7em;
                font-weight: bold;
            }
      
        //    li{
        //        display: flex;
        //        text-align: center;
        //    }
        
    }
     [dir=rtl].List-Container{
        display: flex;
    margin-right: -4%;
    }
         .Header{
            height: 9px;
            width: 40px;
            border: 2px solid #65AAA4;
            border-right-width: 0px;
            border-radius: 10px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-top: 7px;
            margin-left: 3%;
            background-color: #65AAA4;
        
            .content{
                border-top-width: 1px;
                width: 37px;
                align-items: stretch;
                display: flex;
                background-color: $primary_txt;
                // height: -7px;
                border-top-style: dashed;
              
            }
    
            .Icon-Style{
                width: 15%;
                height: 7px;
                margin-inline-start: 2px;
                background-color: #03403A;
                border-radius: 10px;
                border: 2.5px solid #14766B;
            }
         }
        
         .Circle-Wrap{
             display: flex;
             flex-direction: column;
             align-items: center;
             justify-content: center;
             text-align: center;
         }    
         
         .Circle{  
            border: 2.5px solid;
            text-align: center;
            align-items: center;
            justify-content: center;
            height: 30%;
            width: 23px;
            border-radius: 30px;
            margin-left: -23%;
    
        }
        [dir=rtl].Circle{  
            border: 2.5px solid;
            text-align: center;
            align-items: center;
            justify-content: center;
            height: 30%;
            width: 23px;
            border-radius: 30px;
            margin-right: -20%;
    
        }
        .Seperator-Wrapper{
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            [dir=ltr].Seperator{
                height: 13%;
                width: 86%;
                border: 2px solid #04403B;
                border-left-width: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 14.1%;
                margin-left: -12.2%;
            
                .content{
                    border-top-width: 1px;
                    width: 37px;
                    align-items: stretch;
                    display: flex;
                   
                    // height: -7px;
                    border-top-style: dashed;
                  
                }
        
            }

            [dir=rtl].Seperator{
                height: 13%;
                width: 86%;
                border: 2px solid #04403B;
                border-left-width: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 14.1%;
                margin-right: -13.2%;
            
                .content{
                    border-top-width: 1px;
                    width: 37px;
                    align-items: stretch;
                    display: flex;
                   
                    // height: -7px;
                    border-top-style: dashed;
                  
                }
        
            }
        }
        [dir=rtl].Footer{
                  
            .Seperator{
                flex-direction: row;
                height: 13%;
                width: 40px;
                border: 2px solid #04403B;
                border-radius: 10px;
                border-left-width: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 14.1%;
                margin-right: -42.2%;
            
                .content{
                    border-top-width: 1px;
                    width: 37px;
                    align-items: stretch;
                    display: flex;
                   
                    // height: -7px;
                    border-top-style: dashed;
                  
                }
                .Icon-Style{
                    width: 20%;
                    height: 7px;
                    margin-inline-start: 2px;
                    background-color: #03403A;
                    border-radius: 10px;
                    border: 2.5px solid #14766B;
                }
            }
         }
    
        [dir=ltr].Footer{
                  
            .Seperator{
                flex-direction: row;
                height: 13%;
                width: 86%;
                border: 2px solid #04403B;
                border-radius: 10px;
                border-left-width: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 14.1%;
                margin-left: -8.2%;
            
                .content{
                    border-top-width: 1px;
                    width: 37px;
                    align-items: stretch;
                    display: flex;
                   
                    // height: -7px;
                    border-top-style: dashed;
                  
                }
                .Icon-Style{
                    width: 20%;
                    height: 7px;
                    margin-inline-start: 2px;
                    background-color: #03403A;
                    border-radius: 10px;
                    border: 2.5px solid #14766B;
                }
            }
         }
        }
    }
    
    .Description{
    flex:1.7;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    ;
    // background-color: black;
        .Content-Seperator{
            opacity: 0.3;
            border-left: 2px dashed;
            height: 18em;
            display: flex;
            flex: 0.1;
            margin-top: 12px; 
            margin-inline-start: 3%;      
        }
        .Crop-Content{
            // background-color: red;
            display: flex;
            flex-direction: column;
            flex: 3;
            .Crop-Header{
                display: flex;
                // align-items: center;
                // justify-content: center;
                // text-align: center;
                color: #C4FFF9;
                text-transform: uppercase;
                font-weight: bolder;
                font-size: 0.8em;
                margin-top: 20px;
                // margin-inline-start: 31%;
             
                width: 100%;
            flex-direction: column;
    
            }
            .Crop-Header-Seperator{
                border-top: 1px solid;
                // width: 100%;
                opacity: 0.3;
                // margin-inline-start: 20px;
                margin-inline-end: 20px;
                margin-bottom: 9px;
            }
                .Crop-Body{
                    display: flex;
                    flex-direction: row;
                    // justify-content: space-evenly;
                  
                    .SubTitle{
                        // flex:1;
                        margin-inline-end: 18%;
                        // text-align: center;
                        .Crop-Detail{
                            margin-top: -10px;
                        }
                    }

                }
            .crop-added-details-container{
                display: flex; 
                align-self: flex-end;
                flex-direction: column;
                margin-top: auto;
                margin-inline-end: 2%
            }
        }
    }
 
    }

    .Content{
        flex: 2 1;
        background-color: #05342F;
        border: 1px solid #46AAA040;
        padding-inline-start: 20px;
        padding-top: 20px;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        .Tabs-View{
            display: flex;
            .Tab-Wrapper{
                margin-inline-start: 20px;
                p{
                    font-size: 0.9em;
                }
                .selectedTabBorder{
                    border-top-width: 2px;
                    border-top-style: solid;
                    margin-top: -8px;
                    border-top-color: #73E4CC;
                 }
            }
         
        }
    }

    .OptimalReading-Container{
        display: flex;
        flex-direction: column;
        // width: 100%;
        height: 100%;
        flex: 1 1;
        margin-top: 20px;
        margin-inline-start: 20px;
        .Header{
            color: $primary_txt;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 0.9em;      
        }
        .Reading-List{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-height: 20em;
            overflow-x: scroll;
            .Item-Wrapper{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-inline-start: 5%;
                margin-top: 18px;
            }
        }
        .Averagre-StatusBar{
            width: 60px;
            height: 4px;
            border-radius: 10px;
            margin-bottom: 12%;
            
        }
        // .List-Wrapper{
        //     display: flex;
        //     justify-content: center;
        //     /* align-items: center; */
        //     /* text-align: center; */
        //     flex-wrap: wrap;
        //     width: 100%;
        //     max-height: fit-content;
        //     overflow-y: scroll;
        //     .col-sm-2 {
        //         padding-left: 6px !important;
        //         padding-right: 6px !important;
        //         // margin-inline-start: 30px !important;
        //     }
        //     .Averagre-StatusBar{
        //         width: 60px;
        //         height: 4px;
        //         border-radius: 10px;
        //         margin-bottom: 12%;
                
        //     }
        // }
        .Average-Reading-Container{
            margin-top: 3%;
            .empty-data-state-container {
                padding: 2em !important;
            }
        }
    }
 
    .Diseases-Container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex: 1 1;
        margin-top: 20px;
        flex-wrap: wrap;
        .NamesList-Container
        {
            display: flex;
            flex:1;
            // background-color: black;
           .Disease-Header-List{
            background-color: #02312C;
            box-shadow: -2px -2px 5px #0b837780;
            border-radius: 60px;
            width: 3%;
            height: 28px;
            margin-top: 5px;
            p{
                text-align: center;
                letter-spacing: 0px;
                color: #73E4CC;
                text-transform: uppercase;
                font-size: 1.3em;
                /* font-weight: bold; */
                margin-top: -2px;
            }
           }
            .Diseases-Item-Wrapper{
                display: flex;
                width: 100%;
                // justify-content: space-evenly;
                align-items: flex-start;
                margin-bottom: 0.8em;
                .Disease-Wrapper{
                    height: 2.5rem;
                    border-radius: 23px;
                    margin-inline-start: 15px;
                    box-shadow: -2px -2px 5px #0b837780;
                    border: 1px solid transparent;
                    &:hover{
                        background: #73E4CC 0% 0% no-repeat padding-box;
                        border: 1px solid #C4FFF9;
                        // border-radius: 37px;
                        cursor: pointer;
                        // opacity: 1;
    
                        p{
                            padding-inline-start: 20px;
                            padding-inline-end: 20px;
                            margin-top: 5px;
                            color:#165F59;
                            font-weight: bolder;
                            font-size: 0.7em;
                        }
                    }
                 
                    // background-attachment: #02312C;
                    border-radius: 23px;
                    box-shadow: -2px -2px 5px #0B837780;
                    p{
                        padding-inline-start: 20px;
                        padding-inline-end: 20px;
                        padding-top: 7px;
                        font-size: 0.7em;
                        color: #73E4CC;
                        text-transform: uppercase;
                        font-weight: bolder;            
                        margin-top: 5px;
                    }
                }

             
            }

        }
        .Diseases-Content{
            display: flex;
            flex: 9;
            flex-direction: row;
            background: #022C27 0% 0% no-repeat padding-box;
            border: 1px solid #46AAA052;
            margin-bottom: 20px;
            margin-inline-end: 20px;
            .Header{
                p{
            color: #C4FFF9;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 1em;
        
                }
            // width: 100%;
            align-self: flex-start;
            margin-inline-start: 20px;
            margin-top: 20px
            
            }
            .Body{
                .carousel {
                    position: relative;
                    /* display: flex; */
                    align-self: flex-start ;
                }
                // justify-content: center;
                align-items: center;
                display: flex;
                flex: 1;
                margin-inline-start: 20px;
                margin-top: 15px;

            }
            .Accordion-Container
            {
                // background-color: white;

                display: flex;
                flex:2;
                flex-direction: row;
                .accordion-list {
                    list-style-type: none;
                    padding: 0;
                    width: 90%;
                    margin-inline-start: 20px;
                    /* justify-content: center; */
                    display: flex;
                    margin-top: 70px;
                    flex-direction: column
                }
                .Content-Seperator{
                    opacity: 0.3;
                    border-left: 2px dashed;
                    height: 92%;
                    display: flex;
                    flex: 0.1;
                    margin-top: 10px; 
                    margin-inline-start: 3%;            
                }
                }
            .Carousel-Container
                {
                    display: flex;
                    flex: 1.2 1;
                    // justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding-bottom: 1em;
                    .Carousel-Image{
                        width:354px;
                        height: 300px;
                    }
                }
        }
    }

    .Fertigation-Container{
        display: flex;
        width: 98%;
        /* height: 100%; */
        flex-wrap: wrap;
        flex: 1 1;
        margin-top: 20px;
        // position: relative;
        /* margin-inline-start: -20px; */
        .empty-data-state-container {
            height:40em;
        }
        .table-Container {
            background-color: transparent;   
            width: inherit;
            height: inherit;
            display: flex;
            flex: 1;
            flex-direction: column;
            /* padding-bottom: 1em; */
            flex-wrap: wrap;
            border-radius: 5px;
        }
       .pagination-container {
            display: flex;
            list-style-type: none;
            position: absolute;
            bottom: 1em;
            /* right: 10px; */
            left: -3em;
            padding-inline-start: 14px !important;
        }
        [dir=ltr].New-Record-Row{
            width:inherit ;
            background-color: #003A35;
            height: 3.5em;
            // margin-top: -75px;
            width: 100%;
            p{
                color:#73E4CC;
                font-weight: 400;
                font-size: 1em;
                text-transform: uppercase;
                margin-top: 15px;
                margin-inline-start: 10px;
            }
        }
        [dir=rtl].New-Record-Row{
            width:inherit ;
            background-color: #003A35;
            height: 3.5em;
            // margin-top: -87px;
            width: 100%;
            p{
                color:#73E4CC;
                font-weight: 400;
                font-size: 1em;
                text-transform: uppercase;
                margin-top: 15px;
                margin-inline-start: 10px;
            }
        }
    }
    
    .Program-Container{
        display: flex;
        width: 100%;
        height: 100%;
        flex: 1 1;
        flex-direction: row;
        padding-bottom: 2em;
        .cards-list{
            display: flex;
            flex-direction: column;
            flex:.9;
            padding-top: 0%;
            .card-with-icon-container {
                margin-top: 3.5em;
                display: flex;
                flex-wrap: wrap;
                width: 16em;
                height: 8em;
                padding-bottom: 1em;
                .icon-container{
                    left:6em;
                }
                .content-container{
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-wrap: wrap;
                    background: #05342F 0% 0% no-repeat padding-box;
                    box-shadow: -4px -2px 7px #0B837765;
                    border-radius: 5px;
                    opacity: 1;
                    width: 16em;
                    height: 8em;
                    .summary{ 
                        font-size:1em;
                        color:$primary ;
                        font-weight:400, 
                    }
                }
                .content-container-selected{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    background: #73E4CC 0% 0% no-repeat padding-box;
                    box-shadow: -4px -2px 7px #0B837765;
                    border: 3px solid #C4FFF9;
                    border-radius: 5px;
                    opacity: 1;
                    width: 16em;
                    height: 8em;
                    .summary{ 
                        font-size:1em;
                        color:$cta-txt ;
                        text-transform: uppercase;
                        font-weight:600, 
                    }
                }
            }
        }
        .Seperator{
            display: flex;
            flex:.1;
            opacity: 0.3;
            // margin-inline-start:20px ;
            // margin-inline-end:20px ;
            border-left: 2px dashed;
            height: 95%;
            display: flex;
            margin-top: 1em;
            margin-inline-start: 2%;
        }
        .programs-list-container{
            display: flex;
            flex-direction: column;
            flex:4 1;
            margin-inline-start:1%;
            margin-inline-end:2%;
            margin-top: 2%;
            width: 60%;
            .programs-names-list{
                .NamesList-Container
                {
                    overflow-x:scroll;
                    display: flex;
                    flex:1;
                    list-style-type:none;
                    padding-inline-start: .3em;
                    padding-bottom: .3em;
                    min-width: 55%;
                    overflow-y: scroll;
                    .Header-List{
                        background-color: #02312C;
                        box-shadow: -2px -2px 5px #0b837780;
                        border-radius: 60px;
                        width: 2.2em;
                        height: 2.2em;
                        margin-top: 5px;
                        align-items: center;
                        padding-inline-start: .7em;
                        padding-inline-end: 1.5em;
                        &:hover{
                            background: #73E4CC 0% 0% no-repeat padding-box;
                            border: 1px solid #C4FFF9;
                            cursor: pointer;
                            p{
                                color:#165F59;
                                font-weight: bold;
                            }
                        }
                        p{
                            text-align: center;
                            letter-spacing: 0px;
                            color: #73E4CC;
                            text-transform: uppercase;
                            font-size: 1.3em;
                            margin-top: -2px;
                        }
                    }
                    .Item-Wrapper{
                        display: flex;
                        // width: 100%;
                        align-items: flex-start;
                        .Wrapper,#Wrapper-selected{
                            height: 2.2em;
                            width: max-content;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 5px;
                            border-radius: 23px;
                            margin-inline-start: 15px;
                            box-shadow: -2px -2px 5px #0b837780;
                            padding-inline-end: 10px;
                            &:hover{
                                background: #73E4CC 0% 0% no-repeat padding-box;
                                border: 1px solid #C4FFF9;
                                cursor: pointer;
                                p{
                                    color:#165F59;
                                    font-weight: bold;
                                }
                                circle{
                                    fill:#05342f,
                                }
                            }
                            p{
                                padding-inline-start: 20px;
                                padding-inline-end: 10px;
                                font-size: 0.7em;
                                color: #73E4CC;
                                text-transform: uppercase;
                                font-weight: 600;    
                                margin-bottom: 0 !important;        
                            }
                        }
                    }
                }
            }
            .programs-content{
                display: flex;
                flex-direction: column;
                margin-top: 2em;
                background: #022C27 0% 0% no-repeat padding-box;
                // border: 1px solid #46AAA052;
                min-height: 30em;
                border-radius: 4px;
                padding-bottom:8em;
                .triangle{
                    position: absolute;
                    width: 0; 
                    height: 0; 
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;    
                    border-bottom: 12px solid #022C27;
                    z-index: 1000 !important;
                    margin-top: -12px;
                    
                }
                .program-description-container{
                    display:flex;
                    flex-direction:column;
                    margin: 1.2em;
                    .program-description-header{
                        display:flex;
                        flex-direction:row;
                        justify-content: space-between;
                        margin-bottom: .5em;
                        .program-name{
                            text-transform: uppercase;
                            color: #C4FFF9;
                            font-weight: 600;
                        }
                        .button-container{
                            background-color: #05342F;
                            background: #022C27 0% 0% no-repeat padding-box;
                            box-shadow: -2px -2px 5px #0B837780;
                            border-radius: 5px;
                            opacity: 1;
                            width:2em;
                            height:2em;
                            margin-right: .3em;
                            margin-left: .3em;
                            display: flex;
                            align-items: center;
                            svg{
                                width:1.1em;
                                height:1.2em;
                                display: block;
                                margin: 0 auto;
                                text-align: center;
                            }
                            &:hover{
                                background: #73E4CC 0% 0% no-repeat padding-box;
                                border: 1px solid #C4FFF9;
                                cursor: pointer;
                                path {
                                    fill: #022C27;
                                }
                            }
                        }
                    }
                    .program-description-content{
                        display:flex;
                        flex-direction:row;
                        font-size: .85em;
                        .program-description-content-column{
                            display:flex;
                            flex-direction:column;
                            margin-inline-end: 10vw;
                            .title{
                                color: #C4FFF9;
                                text-transform: none;
                            }
                            .subtitle{
                                color: #46AAA0;
                                margin-bottom: .8em;
                            }
                        }
                    }
                }
                .dosage-table-container{
                    margin-right: 1.2em;
                    margin-left: 1.2em;
                    .pagination-container{
                        position:absolute;
                        bottom: 8em;
                        padding-inline-start: 25vw !important;
                    }
                    .table-drop-down-view-container{
                        bottom: 8.5% !important;
                        right: 5% !important;
                    }
                    .table-Container{
                        border-radius: 2px !important;
                    }
                }
                .activate-button-container{
                    align-self: center;
                    position: absolute;
                    bottom: 5em;
                }
            }
        }
        .empty-programs-container{
            display:flex;
            flex:4;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            height: 94%;
            border: 1px solid #46AAA040;
            border-radius: 5px;
            margin-inline-start:1%;
            margin-inline-end:2%;
            margin-top: 2%;
            button{
                margin-bottom:2em;
            }
            .modal-body-container{
                display:flex;
                align-items:center;
                justify-content:center;
                flex-direction:column;
                .create-report-image{
                    display: flex;
                    width:50%;
                    flex-wrap: wrap;
                    justify-content: center;
                }
                .input-container{
                    width:50%;
                }
            }
        }
        .empty-programs-header{
            @extend .title;
            @media(min-height: 1000px) { font-size: 1.4em !important; }
            padding-top: 1%;
            margin-bottom: 0.5%;
        }
        .empty-programs-subheader{
            @extend .subtitle;
            @media(min-height: 1000px) { font-size: 1.1em !important; }
            text-align:center;
            letter-spacing: 0px;
            max-width:22em;
            word-wrap:break-word;
            margin-bottom: 1.5%;
        }
        .modal-body-container{
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;
            .archive-program-image{
                display: flex;
                width:40%;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 5%
            }
            .archive-program-header{
                @extend .title;
                @media(min-height: 1000px) { font-size: 1.4em !important; }
                padding-top: 1%;
                margin-bottom: 0.5%;
                margin-top: 3%
            }
            .archive-program-subheader{
                @extend .subtitle;
                @media(min-height: 1000px) { font-size: 1.1em !important; }
                text-align:center;
                letter-spacing: 0px;
                max-width:22em;
                word-wrap:break-word;
                margin-bottom: 10%;
            }
        }
    }
}

[dir='rtl'] .CropPhase-Container{
    .TimeLine .Container .Crop-Image-View {
        width: 310px !important;
        height: 275px;
    }
}

@media (min-width:310px) and (max-width:480px)
{
}


@media (min-width:481px) and (max-width:768px) {

}




@media (max-width:600px){
    .CropPhase-Container .TimeLine {
        flex-direction: column !important;
    }
    .CropPhase-Container .TimeLine .Description .Content-Seperator {
        display: none !important;
    }
    .CropPhase-Container .TimeLine .Description {
        margin-inline-start: 1em;
    }
    .CropPhase-Container .TimeLine .Description .Crop-Content .crop-added-details-container {
        margin-inline-end: 8% !important;
    }
}

@media (min-width:600px) and (max-width:1024px) {
    .CropPhase-Container .TimeLine .Container [dir=ltr].Line .Header {
        width: 25px !important;  
      }
  
      .CropPhase-Container .TimeLine .Container [dir=ltr].Line .Seperator-Wrapper [dir=ltr].Seperator .content {
          width: 25px !important;  
   
      }
      .CropPhase-Container .TimeLine .Container [dir=ltr].Line [dir=ltr].Footer .Seperator .content {
          width: 25px !important;  
      }
      .CropPhase-Container .TimeLine .Container .Crop-Image-View {
          width: 240px !important;
      }
}

@media (min-width:1024px) and (max-width:1400px) {
    .CropPhase-Container .TimeLine .Container [dir=ltr].Line .Header {
        width: 30px !important;  
      }
  
      .CropPhase-Container .TimeLine .Container [dir=ltr].Line .Seperator-Wrapper [dir=ltr].Seperator .content {
          width: 30px !important;  
   
      }
      .CropPhase-Container .TimeLine .Container [dir=ltr].Line [dir=ltr].Footer .Seperator .content {
          width: 30px !important;  
      }
      .CropPhase-Container .TimeLine .Container .Crop-Image-View {
          width: 260px !important;
      }
}

@media (min-width:1600px)
{
    .Description{
        .Content-Seperator{
            height: 22em; 
        }
    }
    .CropPhase-Container .Diseases-Container .Diseases-Content .Body {
        align-items: center;
        display: flex;
        flex: 1 1;
        margin-inline-start: 20px;
        // margin-top: -101px;
    }
    .Carousel-Image{
        width:500px !important;
        height: 422px !important;
    }
    .CropPhase-Container .TimeLine .Container [dir=rtl].Line {
        display: flex;
        margin-top: -9%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        bottom: -40px;
        position: absolute;

    }

    .CropPhase-Container .TimeLine .Container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex: 0.8 1;
        margin-top: -37px;
    }
}

@media (min-width: 3000px){
    .CropPhase-Container .TimeLine .Container [dir=ltr].Line .Header {
      width: 50px !important;  
    }

    .CropPhase-Container .TimeLine .Container [dir=ltr].Line .Seperator-Wrapper [dir=ltr].Seperator .content {
        width: 50px !important;  
 
    }
    .CropPhase-Container .TimeLine .Container [dir=ltr].Line [dir=ltr].Footer .Seperator .content {
        width: 50px !important;  
    }
    .Description{
        .Content-Seperator{
            height: 24em; 
        }
    }
    .CropPhase-Container .TimeLine .Container .Crop-Image-View {
        width: 400px !important;
        height: 330px !important;
    }
    .CropPhase-Container .TimeLine .Container [dir=ltr].Line {
        // top: 15% !important;
        left: -30px !important;
    } 

    .CropPhase-Container .TimeLine .Container {
        flex: 0.5 !important
    }
}

