.accordion-tab {
    background-color: $accordion-bg;
    border: 1px solid $accordion-border;
    height: 2rem;
    display: flex;
    align-items: center;
    padding-inline-start: 1.5em;
    -webkit-appearance: none;
    box-shadow: 0px 3px 6px $slider_box_shadow;
    -webkit-box-shadow: 0px 3px 6px $slider_box_shadow;
}

.accordion-tab-title {
    margin-inline-end: auto;
    margin-bottom: 0;
    @extend .gadget-title;
    color: $accordion-title  !important;
}

.accordion-expand-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.3em;
    height: 2.3em;
    border: 0;
    outline: none !important;
    background-color: transparent;
    margin-inline-end: 2em;
}

[dir='rtl'] .accordion-expand-button {
    transform: rotate(180deg);
}

.accordion-tab-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: $dark-button-background;
    border: 1px solid $accordion-border;
    overflow: scroll;
    margin-bottom: -3px;
    padding-block-start: 20px;
}

.accordion-list {
    list-style-type: none;
    padding: 0;
}

.accordion-list-item {
    margin-bottom: 3px;
}