.empty-reports-container,
.view-all-modal-container {
    display: flex;
    margin-top: -10%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .modal-body-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .proceed-button {
            padding-inline: 3rem;
        }

        .create-report-image {
            display: flex;
            width: 50%;
            flex-wrap: wrap;
            justify-content: center;
        }

        .input-container {
            width: 50%;

            .input-group-prepend {
                top: 0;
            }
        }
    }
}

.empty-reports-container {
    margin-block-start: -4rem;
}

.modal-body-container {

    .proceed-button {
        padding-inline: 3rem;
    }
}

.empty-reports-header {
    @extend .title;
    font-size: calc(12px + (14 - 10) * ((100vw - 320px) / (2800 - 320)));
    // @media(min-height: 1000px) {
    //     font-size: 1.4em !important;
    // }

    padding-top: 1%;
    margin-bottom: 0.5%;
}

.empty-reports-subheader {
    @extend .subtitle;
    font-size: calc(12px + (14 - 11) * ((100vw - 320px) / (2800 - 320)));
    // @media(min-height: 1000px) {
    //     font-size: 1.1em !important;
    // }

    text-align: center;
    letter-spacing: 0px;
    max-width: 25em;
    word-wrap: break-word;
    margin-bottom: 1.5%;
}

.modalBackground .input-container {
    svg #dashboard {

        line,
        path {
            stroke: #C4FFF9;
        }
    }

}

.CreateReport-Container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 100vh;
    margin-bottom: 20px;
    margin-block-start: 1em;

    .Wrapper-Container {
        width: 100%;
    }

    .c-sidebar {
        width: 17% !important;
    }

    .report-name-container {
        top: 0;
        left: 16.3em;
        display: flex;
        flex-direction: row;
        background-color: #03403A;

        .navigation-container {
            margin-inline-start: auto;
        }

        .Export-Container,
        .delete-button {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // flex-wrap: wrap;
            // width: 24px;
            // height: 24px;
            width: 1.7rem !important;
            height: 1.7rem !important;
            padding-inline: 0.3rem;
            border-radius: 5px;
            margin-inline-end: 0.5em;
            outline: none !important;
            border: none !important;
            margin-inline-start: 8px;
            position: relative;
            //top: 3px;
        }

        .delete-button:not(.disabled):hover {
            background-color: $primary;
        }
    }

    .nav-link {
        color: $primary-txt;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        justify-content: center !important;
    }

    .c-sidebar .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        border: none !important;
        //padding: 0.5rem 1rem;
    }

    .Panel-Container {

        .Content-Container {
            display: flex;
            height: 80vh;
            // width: 100%;
            flex-direction: column;
            position: relative;

            @media(max-height: 650px) {
                height: 70vh;
            }

            .Sensor-Selection-Content {
                max-height: 75vh;
                overflow-y: scroll;
                font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
            }
        }
    }

    .show {
        width: 80%;
    }

    .Map-Container {
        // display:flex ;
        // flex:1;
        width: 100%;
        //height: 15vh;
        position: relative;
        margin-top: 0.5em !important;
        margin-block-end: 24.2em;
    }

    .Graph-Container {
        display: flex;
        position: relative;
        flex-direction: column;
        min-height: 50vh;
        background: $container-background 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding-bottom: 10%;
        width: 100%;

        .empty-state-graph {
            background-color: $empty-state-graph ;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-block-start: 1em;
            padding-block: 3em;

            svg {
                width: 15%;
                height: 45%;
            }
        }

        .graph-title {
            //@extend .gadget-title;

            // @media(min-height: 1000px) {
            //     // font-size: 1.15em !important; 
            //     font-size: 12px !important;
            // }

            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 1.5em !important;
            position: relative;

            h6 {
                margin-inline-end: .5em;
                margin-bottom: .2em;
            }

            .buttons-container {
                position: absolute;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;

                button {
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    width: 2rem;
                    height: 2rem;
                    padding-inline: 0.3rem;
                    background: $container-background 0% 0% no-repeat;
                    //box-shadow: $dark-button-box-shadow !important;
                    $lower-shadow: 4px 4px 8px darken($container-background, 3%);
                    $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
                    -webkit-appearance: none !important;
                    box-shadow: $upper-shadow, $lower-shadow !important;
                    -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
                    border-radius: 5px;
                    margin-inline-end: 1em;
                    outline: none !important;

                    svg {
                        display: inline-block;
                        vertical-align: middle;
                        width: 90%;
                    }
                }

                button:not(.disabled):hover {
                    background-color: $primary;
                    border: 2px solid $primary-txt;
                    transition: 0.3s ease-out;
                }

                .disabled {
                    opacity: 0.4
                }
            }
        }

        .sliders-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 1.5em !important;
            position: relative;

            p {
                margin: 0;
                margin-inline-end: 1em;
                text-transform: capitalize;
                color: $secondary-txt;
            }

            .daily-slider {
                margin-inline-start: 1em;
            }

            .rate-slider {
                align-self: center;
                margin-inline-start: 2em;
            }
        }

        .data-filter-container {
            position: relative;
            align-self: center;
            width: 96%;
            background-color: $filter-background;
            border: 1px solid $filter-border;
            border-radius: 10px;
            padding: 20px;
            padding-bottom: 40px;
            margin-top: 0.2em;
            display: flex;
            flex-direction: row;
            align-items: center;

            .date-duration-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-inline-end: 5em;
                margin-bottom: 10px;

                .date-duration-label {
                    margin: 0;
                    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
                }

                .calendar-button {
                    position: relative;
                    width: 220px;
                    height: 35px;
                    background: $calendar-background 0% 0% no-repeat padding-box;
                    //box-shadow: $dark-button-box-shadow;
                    $lower-shadow: 4px 4px 8px darken($accordion-bg, 6.5%);
                    $upper-shadow: -3px -3px 6px lighten($accordion-bg, 0.5%);
                    -webkit-appearance: none !important;
                    box-shadow: $upper-shadow, $lower-shadow !important;
                    -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
                    border-radius: 5px;
                    outline: none !important;
                    color: $primary;
                    margin-inline-start: 10px;

                    span {
                        padding-inline-start: 1.7em;
                        color: $primary;
                        font-weight: 500;
                        font-size: 0.92em
                    }
                }

                .calendar-button::before {
                    content: url('../../assets/icons/svg/MainMap/calendar.svg');
                    width: 2em;
                    height: 1.5em;
                    z-index: 1000 !important;
                    position: absolute;
                    left: 0.3em;
                    top: 0.75em;
                }

                .triangle {
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 4.5em;
                    inset-inline-start: 11.5em;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid $light-container-background;
                    z-index: 1000 !important;
                }

                .calendar {
                    position: absolute;
                    inset-inline-start: 4.5em;
                    top: 5em;
                    z-index: 100 !important;

                    .date-picker-container {
                        width: 17em;
                        background-color: $light-container-background !important;
                    }
                }

            }

            .Checkbox-Container .Checkbox-View {
                background-color: $calendar-background !important;
                $lower-shadow: 3px 4px 8px #08322f;
                $upper-shadow: -3px -3px 6px lighten($tab-background, 5%);
                box-shadow: $upper-shadow, $lower-shadow !important;
                -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
            }

            .aggregation-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                z-index: 50 !important;
                margin-inline-start: 2.5em;
                position: relative;
                margin-top: 5px;

                .aggregation-key {
                    margin-block: 0;
                    margin-inline-end: 1em;
                    display: flex;
                    align-items: center;
                }

                .drop-down-selector-container {
                    width: 7em;
                    height: 4em;
                    margin-inline-end: 5%;
                    background: $calendar-background 0% 0% no-repeat padding-box;
                    -webkit-appearance: none !important;
                    box-shadow: $dark-button-box-shadow;
                    -webkit-box-shadow: $dark-button-box-shadow !important;

                    .current-choice {
                        color: $primary;
                    }

                    .choices-list {
                        left: 0.8em;
                        width: 7em;
                        z-index: 5000;
                    }

                    .triangle {
                        left: 2.5em
                    }

                    .list-item {
                        display: inline-flex;
                    }
                }
            }

            .aggregation-container::before {
                content: ' ';
                position: absolute;
                background: #13554F;
                bottom: 0;
                left: -1.2em;
                top: 0;
                height: 4em;
                width: 1px;
            }

            .error-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: absolute;
                top: 2.5em;
                left: 0;

                .error-icon {
                    svg {
                        text {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center !important;
                        }
                    }
                }

                .error-message {
                    color: $error-message;
                    margin-inline-start: 0.5em;
                    padding-top: 0.2em !important;
                    font-size: 0.95em !important;
                }
            }

            .multiple-scales-wrapper {
                margin-inline-start: 2em;
            }

            .apply-action {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.7em;
                margin-inline-start: auto;
                color: $primary;
                background-color: #73E4CC;
                color: #165F59;
                height: 2.2em;
                border-radius: 5px;
                outline: none;
                border: none;
                font-weight: 600;
                opacity: 0.2;
            }

            .apply-action-enabled {
                opacity: 1;
            }

            .apply-action-enabled:hover {
                background-color: #19B392;
                color: #C4FFF9;
            }

            .proceed-icon {
                padding-inline: 0.3em;
            }
        }

        .graph-div {
            padding-top: 0 !important;
            // margin-top: 2em;
            // align-content: center;
            // margin-inline-start: 2em;
            // margin-inline-end: 3%;
        }
    }

    .Button-Container {
        margin-inline: auto;
        margin-top: auto;
        margin-bottom: 10px;

        .proceed-button {
            padding-inline: 2rem !important;
        }
    }

    .Panel-Container {
        height: 100%;
        width: 100%;

        .Tabs-Container {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-evenly;

            .Selected-Tab-View {
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                -webkit-appearance: none !important;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
                border-radius: 5px;
                background-color: $primary;
                border: 2px solid $primary-txt;
                transition: 0.3s ease-out;
                outline: none !important;
            }

            .Tab-View,
            .Blocked-tab-view {
                position: relative;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                -webkit-appearance: none !important;
                //box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                //-webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
                $lower-shadow: 3px 4px 8px darken($tab-background, 4.3%);
                $upper-shadow: -3px -3px 6px lighten($tab-background, 5%);
                box-shadow: $upper-shadow, $lower-shadow !important;
                -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
                border-radius: 5px;
                outline: none !important;
                background-color: $tab-background ;

                .Tab-Content {
                    opacity: 0.5;
                }
            }

            .Tab-View:hover {
                background-color: $primary;
                border: 2px solid $primary-txt;
                transition: 0.3s ease-out;
            }

            .Blocked-tab-view:hover {
                display: block;
            }

            .Blocked-tab-view:hover:after {
                background-size: 500px 500px;
                background: $blocked-tab-background url("../../assets/icons/svg/constants/disabled.svg") no-repeat center;
                opacity: 0.6;
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 100%;
                height: 100%;
            }

            .Tab-Content {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                #sensor-icon {
                    width: 90%;
                    height: 90%;
                }

                #plot-icon {
                    width: 65%;
                    height: 65%
                }

                #schedule-icon {
                    width: 60%;
                    height: 60%
                }

                #report-icon {
                    width: 65%;
                    height: 65%
                }
            }
        }

        .Content-Container {
            margin-inline: 20px;
            margin-top: 10px;

            .Sensor-Selection-Content {

                .type-name {
                    text-transform: capitalize;
                    padding-inline-start: 10px;
                }

                .list-wrapper {
                    margin-bottom: 20px;
                }

                .category-name {
                    margin-top: 10px;
                    color: #46AAA0;
                    //font-size: 1.2em;
                    font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (2800 - 320)));
                    text-transform: capitalize;
                }

                h6 {
                    margin-bottom: .2em;
                    @extend .gadget-title;
                    font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (2800 - 320)));

                    @media(min-height: 1000px) {
                        font-size: 1.15em !important;
                    }
                }

                p {
                    width: 80%;
                    @extend .gadget-subtitle;
                    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

                    @media(min-height: 1000px) {
                        font-size: 1.15em !important;
                    }
                }
            }

            ol {
                padding: 0;
                width: 100%;
                height: 100%
            }

            .List-Container {
                margin-top: 10px;


                .List {
                    padding-inline-start: 7px;
                    padding-top: 4%;
                    display: flex;
                    flex-direction: row;

                    .Checkbox-Title {
                        //font-size: 0.95em;
                        font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
                    }
                }

                .Plot-Lists {
                    padding-inline-start: 10px;
                    color: #46AAA0;
                }

            }
        }

    }

    .c-icon {
        fill: #73e4cc;
        stroke: #73e4cc;
        background-color: #1b7c73;
        border-radius: 35px;
        padding: 4px;
        width: 1.3rem !important;
        height: 1.3rem !important;
    }
}

.chart-types-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    button:hover,
    button:focus,
    .area-selected,
    .bar-selected,
    .line-selected {
        background-color: $primary;
        border: 2px solid $primary-txt;
        transition: 0.3s ease-out;
    }
}

.filter-button {
    display: flex;
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 5px;
    // border-style: none;
    // box-shadow: -3px -2px 3px #338E875D ;
    background: $dark-button-background 0% 0% no-repeat padding-box;
    outline: none !important;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.chart-type-title {
    margin-bottom: .2em;
    @extend .gadget-title;
    font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (2800 - 320)));

    @media(min-height: 1000px) {
        font-size: 1.15em !important;
    }
}

.chart-type-subtitle {
    @extend .gadget-subtitle;
    width: 80%;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

    @media(min-height: 1000px) {
        font-size: 1.15em !important;
    }

    padding-bottom:15px
}

.chart-type-tab-content-container {
    margin-inline: -10px;
}

.info-title {
    @extend .gadget-title;

    @media(min-height: 1000px) {
        font-size: 1.15em !important;
    }

    margin-inline-start: 1.6em !important;
}

.info-data {
    @extend .gadget-subtitle;

    @media(min-height: 1000px) {
        font-size: 1.15em !important;
    }

    margin-inline-start: 1.6em !important;
    text-transform: capitalize !important;
}

.radio-button-label {
    padding-inline: 10px;
    @extend .subtitle;
    color: #73e4cc;
    font-size: calc(11px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));

    @media(min-height: 1000px) {
        font-size: 1.1em !important;
    }

    margin-bottom: 0;
}

.chart-type-options-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .radio-buttons-container {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        width: 100%;
        padding-inline-start: 10px;
        justify-content: space-evenly;
    }
}

.chart-type-option-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    // margin-inline-end: 10%
}

.chart-type-option-label {
    @extend .subtitle;

    @media(min-height: 1000px) {
        font-size: 1.1em !important;
    }

    padding-top:5%;
    min-height:20px;
}

.selection-lists {
    overflow-y: scroll;
    height: 63vh;
    margin-top: 0em;
    padding-bottom: 10px;
    scrollbar-width: none !important;
}

.graph-loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // margin-top: 5%;
    #graph-loader {
        width: 20em;
        height: 15em
    }
}

.generating-title {
    color: $primary-txt
}

.generate-subtitle {
    color: $secondary-txt
}

.add-chart-button {
    margin-bottom: 2em;
    align-self: flex-end
}

.graph-panel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .graph-list {
        list-style-type: none;
        padding: 0;
        margin-top: 2em;
        max-height: 75vh;
        overflow-y: scroll;
        width: 90%;

        .graph-card-wrapper {
            position: relative;
        }

        .graph-card-container {
            //width:20em;
            width: 100%;
            min-height: 7.5em;
            background: $graph-card-background 0% 0% no-repeat padding-box;
            border-radius: 5px;
            margin-bottom: 2em;
            padding: 1em;

            .graph-name {
                margin-bottom: 0.5rem;
                color: $primary-txt;
                text-transform: uppercase;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            }

            .graphIndex {
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            }

            .graph-types-container {
                margin-bottom: 0.5rem;

                .graph-types-key {
                    color: $primary-txt
                }

                .graph-types-value {
                    color: $secondary-txt
                }
            }

            .buttons-container {
                display: flex;
                flex-direction: row;
                margin-top: 1em;

                .button-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    width: 2em;
                    height: 2em;
                    -webkit-appearance: none !important;
                    box-shadow: -2px -2px 5px $action-button-box-shadow;
                    -webkit-box-shadow: -2px -2px 5px $action-button-box-shadow !important;
                    border-radius: 5px;
                    margin-inline-end: 0.5em;
                    outline: none !important;

                    &:not(.disabled):hover {
                        background-color: $primary;
                    }
                }

                .disabled {
                    opacity: 0.4;
                }
            }

            .undo-text {
                display: none;
                position: absolute;
                bottom: 1em;
                left: 1em;
                color: $primary;
                opacity: 1 !important;
                z-index: 1000 !important;
            }

            [dir='rtl'] .undo-text {
                right: 1em !important;
                left: inherit !important
            }
        }
    }
}

.new-graph-button {
    color: $primary;
    height: 2.5em;
    width: 8em;
    position: absolute;
    right: 1.5em;
    border-radius: 5px !important;
    background: $sidebar 0% 0% no-repeat padding-box !important;
    outline: none
}

[dir='rtl'] .new-graph-button {
    left: 1.5em;
    right: inherit
}

.selected-graph-container:after {
    opacity: 0.9;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    z-index: 100 !important;
    background-color: $graph-card-overlay;
}

.selected-graph-container .undo-text {
    display: block !important;
}

[dir='rtl'] .selected-graph-container .undo-text {
    right: 1em !important;
    left: inherit !important
}

.view-all-reports-view-container {
    padding-block-end: 3%;

    .pagination-container {
        bottom: 0 !important;
        left: 0 !important;
    }

    .reports-list-container {
        margin-top: 2em;
        list-style-type: none;
        padding-inline-start: 0 !important;
        padding-bottom: 1.5em;
        position: relative;

        .report-card-container {
            background: $container-background 0% 0% no-repeat padding-box;
            border-radius: 10px;
            margin-block-end: 1.5em;
            padding: 1.5em;
            position: relative;
            padding-bottom: 3em;
            cursor: pointer;

            .add-to-dashboard {
                position: absolute;
                right: 1em;
                bottom: 1em;
                text-transform: uppercase;
                color: #73E4CC
            }

            .Checkbox-Container .Checkbox-View {
                -webkit-appearance: none !important;
                background: $container-background 0% 0% no-repeat padding-box !important;
                //box-shadow: $dark-button-box-shadow !important;
                //-webkit-box-shadow: $dark-button-box-shadow !important;
                box-shadow: 3px 3px 6px #000000, -1.5px -1.5px 5px #73e4cc8f !important;
                -webkit-box-shadow: 3px 3px 6px #000000, -1.5px -1.5px 5px #73e4cc8f !important
            }

            .report-info {
                font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (2800 - 320)));
            }
        }

        .selected-card-container {
            -webkit-appearance: none !important;
            box-shadow: 4px 4px 8px #73E4CC40, -4px -4px 8px #73E4CC40;
            -webkit-box-shadow: 4px 4px 8px #73E4CC40, -4px -4px 8px #73E4CC40 !important;
        }

        .drop-down-view-container {
            position: absolute;
            bottom: -0.7em;
            right: 1em;
            display: flex;
            flex-direction: row
        }

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            p {
                margin-block: 0 !important;
            }

            .report-name {
                color: $primary-txt;
            }

            .creation-date {
                color: $secondary-txt
            }

            .report-info {
                margin-inline-start: 0.5em;
            }

            .report-icon {
                margin-inline-start: 0.5em;

                svg {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    width: 2.5em;
                    height: 2.5em;
                }
            }

            .header-buttons-container {
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                flex-direction: row;

                .button-design {
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    margin-inline-start: 10px;
                    //flex-wrap: wrap;
                    display: inline-block;
                    vertical-align: middle;
                    background: $container-background 0% 0% no-repeat padding-box;
                    $lower-shadow: 4px 4px 8px darken($container-background, 3%);
                    $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
                    -webkit-appearance: none !important;
                    box-shadow: $upper-shadow, $lower-shadow !important;
                    -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
                    border-radius: 5px;
                    box-sizing: border-box;
                    border: 2px solid transparent;
                    outline: none;
                    width: 2rem !important;
                    height: 1.9rem !important;
                    padding-inline: 0.3rem;

                    svg {
                        display: inline-block;
                        vertical-align: middle
                    }
                }

                .single-action-button {
                    @extend .button-design;
                }

                .single-action-button:not(.viewer):hover {
                    background-color: $primary;
                    border: 2px solid $primary-txt;
                    transition: 0.3s ease-out;
                }
            }

            .view-report-tooltip {
                margin-inline-start: 0px;
                inset-inline-start: -50px;
            }

            .second-tooltip {
                inset-inline-start: -37px;
            }
        }

        .content {
            .charts-summary {
                margin-inline-start: 5.4em;
                margin-top: 0.8em;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

                .charts-count {
                    color: $primary-txt
                }

                .sensor-types {
                    color: $secondary-txt
                }
            }

            .graph-cards-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                overflow-x: scroll;
                max-width: 100em;
                margin-top: 1em;

                .see-more-graphs {
                    background-color: transparent;
                    border: none;
                    color: $primary;
                    outline: none
                }

                ul {
                    display: flex;
                    flex-direction: row;

                    .graph-card-container {
                        display: flex;
                        flex-direction: row;
                        background: $empty-state-graph 0% 0% no-repeat padding-box;
                        border: 1px solid $secondary-txt-dark-opacity;
                        border-radius: 5px;
                        height: 20em;
                        margin-inline-end: 2em;
                        padding: 1em;

                        p {
                            margin: 0;
                        }

                        .graph-information {
                            width: 15em;
                            font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (2800 - 320)));

                            .line-seperator {
                                width: 100%;
                                border-color: $secondary-txt-dark-opacity;
                                height: 0.1em;
                            }

                            .card-title {
                                color: $primary-txt;
                            }

                            .key {
                                color: $primary-txt;
                                text-transform: capitalize
                            }

                            .value {
                                margin-bottom: 0.5em;
                            }
                        }

                        .graph-image {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            width: 13em;
                            height: 100%;

                            svg {
                                width: 13em;
                                height: 13em;
                            }
                        }
                    }
                }
            }
        }
    }

    .all-reports-buttons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-inline-start: 1em;
        padding-top: 10px;

        .Checkbox-Container .Checkbox-View {
            background: $main_layer 0% 0% no-repeat padding-box !important;
            -webkit-appearance: none !important;
            box-shadow: $dark-button-box-shadow !important;
            -webkit-box-shadow: $dark-button-box-shadow !important;
        }

        .all-action-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-inline-start: 10px;
            width: 25px;
            flex-wrap: wrap;
            height: 28px;
            padding-inline: 3px;
            background: $main_layer 0% 0% no-repeat padding-box;
            -webkit-appearance: none !important;
            box-shadow: $dark-button-box-shadow !important;
            -webkit-box-shadow: $dark-button-box-shadow !important;
            border-radius: 5px;
            outline: none;

        }

        .hovered:hover {
            background-color: $primary;
            border: 2px solid $primary-txt;
            transition: 0.3s ease-out;
        }

        .delete {
            margin-inline-start: 15px;
        }

        .new-report-button {
            display: flex;
            flex-wrap: wrap;
            margin-inline-start: auto;

            button {
                width: 11em !important;
                height: 2.5em !important;

                label {
                    font-size: 0.8rem !important;
                }
            }

            button::after {
                width: 20% !important
            }
        }
    }

}


[dir='rtl'] .add-to-dashboard {
    left: 1em !important;
    right: inherit !important;
}

[dir='rtl'] .header-buttons-container {
    left: 0 !important;
    right: inherit !important;

    .view-report-tooltip {
        margin-inline-start: 0px;
        inset-inline-start: -60px !important;
    }

    .select-option .current-choice {
        padding-inline-start: 0%;
        padding-inline-end: 12%;

    }
}

[dir='rtl'] .drop-down-view-container {
    left: 1em !important;
    right: inherit !important;
}

[dir='rtl'] .apply-action {
    left: 10px;
    right: inherit !important
}

[dir='rtl'] .calendar-button::before {
    right: 0.3em;
    left: inherit !important
}

[dir='rtl'] .error-container {
    right: 15px;
    left: inherit !important;

    .error-message {
        margin-top: 0 !important;
        font-size: 0.9em
    }
}

[dir='rtl'].CreateReport-Container {
    .date-duration-container {
        .calendar {
            right: 6em;
            left: inherit !important;

            .date-picker-container {
                width: 18em !important;
            }
        }

        .DayPicker {
            right: 3em;
            left: inherit !important
        }

        .triangle {
            right: 12em;
            left: inherit !important
        }
    }
}

// .c-sidebar-backdrop {
//     display: none;
// }





#map-card-container.mapboxgl-map {
    height: 27em;
}

[dir='rtl'] .CreateReport-Container {
    .buttons-container {
        left: 1em;
        right: inherit !important
    }
}

.CreateReport-Container {
    .Graph-Container {
        .data-filter-container {
            .date-duration-container {
                @media (min-width:3800px) {
                    margin-block-start: -2em;
                }
            }

            @media (min-width:2880px) {
                width: 98%;
            }

            .Checkbox-Container {
                @media (min-width:1920px) {
                    margin-block-start: -0.5em;
                }

                @media (min-width:2880px) {
                    margin-block-start: -1.5em;
                }

                @media (min-width:3800px) {
                    margin-block-start: -2.5em;
                }
            }

            .aggregation-container {
                @media (min-width:1920px) {
                    margin-top: 1.9em;
                    // margin-block-end: -3em;
                }

                @media (min-width:2880px) {
                    margin-top: 2.5em;
                    // margin-block-end: -7em;
                }

                @media (min-width:3800px) {
                    padding-bottom: 0 !important;
                    // margin-block-end: -10em; 
                }
            }

            .Checkbox-Container .Checkbox-Title {
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            }
        }

        .disclaimer-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 55px;
            // width: 50px;
            // max-width: 330px;
            margin-top: 20px;
            padding-inline: 7px;
            transition: 0.3s width ease-in-out;
            background: transparent linear-gradient(90deg, #85F1DA 0%, #55D2BF 46%, #1DAE9F 100%) 0% 0% no-repeat padding-box;
            border-radius: 0px 5px 5px 0px;

            .disclaimer-icon {
                padding-inline-end: 10px;
            }

            .disclaimer-text {
                color: #034943;
                letter-spacing: 0.25px;
                font-weight: 800;
                font-size: 1em;
                font-size: 0.9em;
                line-height: 1.3;
                // width: 250px;
                padding-top: 2px;
                white-space: nowrap;
                /* overflow: hidden; */
                animation: fadeIn 1.4s;
            }


            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }

}

.shrinked {
    width: 80% !important;
}

[dir='rtl'] .disclaimer-container {
    border-radius: 5px 0px 0px 5px !important;
}

[dir='rtl'] .custom-drop-down-selector {
    @extend .selectCheck;

    .choices-list {
        top: 2.5em;
        right: -5.5em;
        left: 0em;
        width: 8.5em;
    }
}

.custom-drop-down-selector {
    @extend .selectCheck;

    .triangle {
        left: 0.5em;
    }

    .choices-list {
        top: 2.5em;
        left: -5.5em;
        width: 8.5em;
    }

    .list-item {
        display: inline-flex;
    }
}

.selectCheck {
    @extend .button-design;
}

.selectCheck .current-choice:not(.viewer):hover {
    background-color: $primary;
    border: 2px solid $primary-txt;
    border-radius: 5px;
    transition: 0.3s ease-out;
}


@media (min-width:1200px) {
    .CreateReport-Container {
        .show {
            width: 74% !important;
        }

        .c-sidebar {
            width: 21% !important;
        }
    }

    .shrinked {
        width: 74% !important;
    }
}

@media (min-width:2000px) {

    .Graph-Container {
        .data-filter-container {
            .date-duration-container {
                .calendar {
                    .date-picker-container {
                        width: 18em !important;
                        background-color: $light-container-background !important;

                        .nice-dates-navigation_current {
                            font-size: 18px !important;
                        }

                        .nice-dates-day_date,
                        .nice-dates-week-header_day {
                            font-size: 18px !important;
                        }
                    }
                }

            }
        }
    }
}

@media (min-width:2000px) {

    .Graph-Container {
        .data-filter-container {
            padding: 30px !important;

            .date-duration-container {
                .calendar {
                    .date-picker-container {
                        width: 18em !important;
                        background-color: $light-container-background !important;

                        .nice-dates-navigation_current {
                            font-size: 18px !important;
                        }

                        .nice-dates-day_date,
                        .nice-dates-week-header_day {
                            font-size: 18px !important;
                        }
                    }
                }

            }
        }
    }
}


@media (min-width:3820px) {
    .CreateReport-Container {
        .show {
            width: 82% !important;
        }
    }

    .shrinked {
        width: 82% !important;
    }
}


@media (max-width:1200px) {
    .CreateReport-Container {
        .show {
            width: 64% !important;
        }

        .c-sidebar {
            width: 27% !important;
        }
    }

    .shrinked {
        width: 64% !important;
    }
}

@media (min-width: 500px) and (max-width: 800px) {
    .CreateReport-Container {
        .show {
            width: 60% !important;
        }

        .c-sidebar {
            width: 100% !important;
        }
    }

    .shrinked {
        width: 60% !important;
    }

    .nav-link {
        display: flex;
        flex-direction: column;
    }

    .new-graph-button {
        position: inherit !important;
        display: block;
        margin-top: 1em;
    }

    .Map-Container,
    .Graph-Container {
        width: 100% !important;
    }
}

// Mobile devices
@media (max-width:500px) {
    .data-filter-container {
        flex-direction: column !important;
        align-items: flex-start !important;
        margin-top: 1em !important;
        padding: 3% !important
    }

    .date-duration-container {
        margin-bottom: 0.8em;
    }

    .aggregation-container {
        margin-inline-start: 0 !important;
        margin-top: 0.8em !important
    }

    .aggregation-container::before {
        display: none !important;
    }

    .CreateReport-Container {
        .c-sidebar {
            width: 100% !important;
        }
    }

}

[dir='rtl'] {
    .CreateReport-Container {
        .nav-link {
            font-weight: 600;
        }

        .Panel-Container .Content-Container .Sensor-Selection-Content h6 {
            font-weight: 400;
        }
    }
}