.manual-meter-table-card {

    .card-header {
        position: relative;

        .table-buttons-container {
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
            flex-direction: row;

            .add,
            .update {
                width: 30px !important;
                height: 30px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                outline: none;
                border: none;
                margin-inline-end: 10px;
                background-color: #02312C;
                box-sizing: border-box;
            }

            .add:hover,
            .update:hover {
                background-color: #73E4CC;
                border: 2px solid #C4FFF9;
            }

            .add {
                svg {
                    display: flex;
                    flex-wrap: wrap;
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    .edit-action,
    .delete-action {
        outline: none;
        border: none;
        background-color: #003A35;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .edit-action:hover,
    .delete-action:hover {
        background-color: #73E4CC;
        border: 2px solid #C4FFF9;
        box-sizing: border-box;
    }

    .edit-action {
        margin-inline-end: 10px;
    }
}

[dir='rtl'] .manual-meter-table-card {
    .card-header {
        .table-buttons-container {
            right: inherit !important;
            left: 10px !important;
        }
    }
}