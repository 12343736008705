.proceed-button {
    background-color: #73E4CC;
    color: #165F59;
    height: 30px;
    padding-inline:10px;
    border-radius: 5px;
    border:none !important;
    outline:none !important;
    margin-top: 15px;
    letter-spacing: 0.16px;
    //font-weight: 600;
    font-size: 14px;
    .proceed-icon{
        margin-inline-start: 5px;
    }
}

.proceed-button:not(.disabled):hover {
    background-color: #19B392;
    color: #C4FFF9;
}