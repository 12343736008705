.Credentials_Wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-block: 1rem;
  padding-inline-end: 5rem;
  flex-wrap: wrap;
  background: url("../../assets/icons/svg/constants/credentialsBg.svg") no-repeat fixed;
  background-position-x: center;
  background-position-y: center;
  background-size: 100%;
  .contact-us-wrapper{
    margin-inline-end: auto;
    align-self: baseline;
    .contact-us{
      background: $secondary_layer;
      &:hover {
          background-color: $primary-txt;
      }
    }
  }
  .mb-4,
  .my-4 {
    margin-bottom: 0.1rem !important;
  }

  .fields-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30vh;
    overflow: scroll;
    margin-bottom: 5px;
  }

  .mobile-span {
    text-transform: lowercase;
    color: #73E4CC;
    margin-block-start: 1rem;
  }

  .form-view {
    padding: 30px 4rem 0px 4rem;
    background-image: linear-gradient(#37aca0, #09635a, #03403a);
    border-radius: 2%;
    height: 83vh;
    display: flex;
    width: 400px;
    flex-direction: column;
    box-shadow: -6px 5px 25px #221e1e;

    .message-error {
      color: #F48585;
    }

    .Issues-Reminder-Message-Wrapper {
      .input-group-prepend {
        white-space: nowrap;
        vertical-align: middle;
        margin-top: 0.1rem;
      }
    }

    .proceed-button {
      margin-inline: 2rem !important;
      margin-top: auto;
      margin-bottom: 3em;
    }
  }

  .logo {
    justify-content: center;
    display: flex;
    margin-bottom: 15px;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  .title {
    font-size: 0.9rem;
    margin-block-end: 0.1em;
    color: #C4FFF9;
    font-weight: bolder;
    text-transform: uppercase;
    text-align: center
  }

  .btn.btn-primary {
    background: var(--cta-primary-normal-state-sub-txt-on-config-bg) 0% 0% no-repeat padding-box;
    background: #73e4cc 0% 0% no-repeat padding-box;
    border-radius: 5px;

    label {
      width: 83%;
      cursor: pointer;
      margin: unset;
      font-size: 13px !important;
    }

    .CTA-AR:disabled:after {
      background: url("../../assets/icons/svg/proceed.svg") no-repeat center !important;
    }

    .CTA-AR::after {
      background: $button-after-bg url("../../assets/icons/svg/proceed.svg") no-repeat center !important;
    }
  }

  .btn.btn-primary::after {
    content: " ";
    width: 15%;
    height: 100%;
    margin-left: 2%;
    border-radius: 30px;
    background: 'url(/static/media/CTA_Arrow.97625f1a.svg)' no-repeat center;
    cursor: pointer;
  }
}

[dir='rtl'] .Credentials_Wrapper {
  background: url("../../assets/icons/svg/constants/credentialsBgAr.png") no-repeat fixed !important;
  padding: 1rem 1rem 1rem 5rem;
  background-position-x: center !important;
  background-position-y: center !important;
  background-size: 100% !important;
}

@media (max-width: 500px) {
  .Credentials_Wrapper {
    padding: 20px;

    .form-view {
      width: 100% !important;
    }
  }
}

.Issues-Reminder-Message-Wrapper {
  .input-group-prepend {
    white-space: nowrap;
    vertical-align: middle;
    //margin-top: 0.1rem;
  }

  form .input-group {
    margin-top: 10px !important;
  }
}

.PhoneInput {
  display: flex;
  align-items: center;

  // $lower-shadow: 3px 4px 8px darken($main_layer, 4.3%);
  // $upper-shadow: -3px -3px 6px lighten($main_layer, 5%);
  // box-shadow: $upper-shadow, $lower-shadow !important;
  // background: $main_layer;
  //   input, button, select, optgroup, textarea {
  //    @extend form .input-group .form-control
  // }
  input {
    position: relative !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: none !important;
    width: 20% !important;
    color: #c4fff9;
    font-size: 14px !important;
    padding: 8px 0 !important;
    box-sizing: border-box !important;
    border-radius: 0 !important;
    border-bottom: 2px solid;
    border-bottom-color: #c4fff92e;
    background: transparent !important;
    background-color: transparent !important;
    z-index: 2;
  }

  span {
    padding-inline-start: 0.8em;
    color: rgb(115, 228, 204);
    padding-bottom: 0px;
    display: inline-block !important;
    position: absolute !important;
    font-size: 14px !important;
    z-index: 1 !important;
    bottom: 16% !important;
    // left: 10% !important;
    transition: 0.5s !important;
  }

  [dir="ltr"] span {
    left: 10% !important;
  }
}


[dir=rtl] {
  .PhoneInput {
    input {
      direction: ltr !important;
      text-align: right;
    }

  }
}

.Toastify__toast-theme--colored.Toastify__toast {
  font-family: nunito_semibold;
  font-size: 13px !important;

  .Toastify__toast-icon {
    margin-inline-end: 10px;
    width: 20px !important;
    flex-shrink: 0;
    display: flex;
  }

  &--error {
    color: #ffc6c6 !important;
    background: #a83736ed !important;

    // .Toastify__toast-icon {
    //     margin-top: -12%;
    // }
    .Toastify__close-button {
      color: #e58c8c !important;
      background-color: #823333 !important;
      width: 14.5% !important;
      height: 14.5% !important;
      border-radius: 50% !important;
      opacity: 1 !important;
    }
  }

  &--warning {
    color: #9d651b !important;
    background: #f0bd6feb !important;

    // .Toastify__toast-icon {
    //     margin-top: -8%;
    // }
    .Toastify__close-button {
      color: #fdd291 !important;
      background-color: #462a0440 !important;
      width: 17% !important;
      height: 17% !important;
      border-radius: 50% !important;
      opacity: 1 !important;
    }
  }

  &--success {
    color: #165f59 !important;
    background: #73e4ccdb !important;

    // .Toastify__toast-icon {
    //     margin-top: -3%;
    // }
    .Toastify__close-button {
      color: #c8f5f1 !important;
      background-color: #03403a4a !important;
      width: 10% !important;
      height: 10% !important;
      border-radius: 50% !important;
      opacity: 1 !important;
    }

    .Toastify__progress-bar {
      background-color: #93fff4 !important;
    }
  }

  .Toastify__close-button {
    svg {
      height: 14px !important;
      width: 12px !important;
      //margin: 0 25% !important;
      margin-top: -3px;
      margin-left: 1.5px;
    }
  }

  .Toastify__toast-body>div:last-child {
    margin-block-start: 0.5em;
  }
}