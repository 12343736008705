.empty-data-state-container{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:2%;
    background-color: #03272340;
    border-radius: 5px;
    padding:4em;
    .icon{
      margin-bottom:2%
    }
    .title{
      text-transform:uppercase;
      color:#C4FFF9;
      font-size: 1em;
      margin-bottom: 0.6em;
    }
    .subtitle{
      text-align: center;
      color:#46AAA0;
      font-size: 1em;
      width: 300px;
      min-height: 50px;
    }
}