.table-Container {
  background-color: $container-background;
  padding-bottom: 3em;
  border-radius: 5px;
  width: 100%;

  .body-wrapper {
    width: 100%;
  }

  ::-webkit-scrollbar {
    height: 7px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #256F68;
    border-radius: 10px;
    width: 2px !important;
    cursor: pointer !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #348C83;

  }

  ::-webkit-scrollbar {
    display: inline-block !important;
  }

  .table-subheader {
    //font-size: 0.8em;
    font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
  }

  .table-title {
    // background-color: $accordion-bg;
    background-color: $sidebar;
    text-transform: uppercase;
    color: $primary;
    display: flex;
    border-radius: inherit;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    flex-wrap: wrap;
    padding-top: 1em;
    // padding-left: .5em;
    padding-bottom: 1em;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: $accordion-title;
      margin-inline-start: 1.5em;
      margin-bottom: 0 !important;
      //font-size: 13px;
      font-size: calc(12px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
    }
  }

  #fixed-columns,
  .scrollable {
    border-collapse: collapse;
    border-width: 1px 1px 0 0;
    border-left: none;
    border-bottom: none;
    border-right: none;
    border-top: none;
    border-width: 0.1px;
    width: 100%;
    height: 100%;

    & th {
      font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320))) !important;
      height: 3em;
    }
  }

  #fixed-columns td,
  .scrollable td,
  #fixed-columns th,
  .scrollable th {
    border: 0.1px solid rgb(27 100 92 / 90%);
    border-left: none;
    border-bottom: none;
    padding-inline: 1.5em;
    text-align: center;
  }

  #fixed-columns td {
    @extend .general;
    padding-inline: 1.5em;
    text-align: center;
  }

  .scrollable td {
    @extend .general;
    margin-block: 0;
    padding-inline: 1.5em;
    text-align: center;
  }

  #fixed-columns tr:hover,
  .scrollable tr:hover {
    background-color: $accordion-bg;
  }

  #fixed-columns th,
  .scrollable th {
    text-align: start;
    // background-color: $container-background;
    background-color: $dark-button-background;
    // color: $accordion-title;
    // color: $secondary-txt;
    @extend .subtitle;
  }

  .scroll {
    display: flex;
    overflow-x: overlay;
    overflow-y: unset;
    width: 100%;
  }

  #fixed-columns,
  .scrollable {
    width: 100%;
  }

  .tables-container {
    display: flex;
    flex-direction: row;
    width: 100%
  }

  .table-cell {
    display: flex;
    align-items: center;
    // padding-inline-start: 10%;
    // justify-content:center;
    white-space: nowrap;
  }
}

[dir='rtl'] {
  .table-Container {
    .table-title h6 {
      font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)));
      //font-weight: 600;
    }
  }

  #fixed-columns th,
  .table-Container .scrollable th {
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320))) !important;
    height: 3.5em !important;
  }

  #fixed-columns td,
  .table-Container .scrollable td {
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320))) !important;
  }
}

@media (max-width: 850px) {
  .table-drop-down-view-container {
    display: none !important;
  }

}