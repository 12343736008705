.contact-us {
    @extend .neuo;
    color: var(--primary);
    background-color: transparent;
    padding: 4px;
    @include lowerShadow($main_layer);
    &:focus{
        outline: none;
    }
    &:hover {
        background-color: $primary-txt;
        svg{
            path {
                fill: $dark-button-background;
            }
        }
    }
}