.sorting-and-filtering-container {
    // margin-top:-2%;
    width: 100%;

    .sorting-and-filtering-buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .filtering-button {
            display: flex;
            justify-content: center;
            align-self: flex-end;
            align-items: center;
            // background-color:#02312C ;
            // box-shadow: $dark-button-box-shadow ;
            -webkit-appearance: none !important;
            background-color: $container-background;
            $lower-shadow: 4px 4px 8px darken($container-background, 3%);
            $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
            box-shadow: $upper-shadow, $lower-shadow  !important;
            -webkit-box-shadow: $upper-shadow, $lower-shadow  !important;
            outline: none;
            border: none;
            width: 30px;
            height: 30px;
            padding: 5px;
            border-radius: 5px;
            margin-inline-end: 1em;
            @extend .neuo;
        }

        .filtering-button:hover {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 1px solid #C4FFF9;
            border-radius: 5px;
        }
    }

    .sorting-container {
        position: relative;

        .sorting-button {
            display: flex;
            justify-content: center;
            align-items: center;
            //background-color:#02312C ;
            //box-shadow: $dark-button-box-shadow ;
            background-color: $container-background;
            -webkit-appearance: none !important;
            $lower-shadow: 4px 4px 8px darken($container-background, 3%);
            $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
            box-shadow: $upper-shadow, $lower-shadow  !important;
            -webkit-box-shadow: $upper-shadow, $lower-shadow  !important;
            outline: none;
            border: none;
            width: 30px;
            height: 30px;
            padding: 5px;
            border-radius: 5px;
            @extend .neuo;
        }

        .sorting-button:hover {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 1px solid #C4FFF9;
            border-radius: 5px;
        }

        .triangle {
            position: absolute;
            width: 0;
            height: 0;
            top: 2.8em;
            left: 0.575em;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #196A63;
            z-index: 1000 !important;
        }

        .sorting-list-container {
            position: absolute;
            //width: 9em;
            width: 13.5em;
            height: 14em;
            top: 3.4em;
            //left: -4.5em;
            left: -10.5em;
            background-color: #196A63;
            border-radius: 3px;
            padding-block: 0.8em;
            z-index: 9000;

            .sorting-label {
                color: #46AAA0;
                text-transform: capitalize !important;
                margin-inline-start: 1.5em;
            }

            .sorting-options-list {
                height: 10em;
                overflow-y: scroll;
                list-style-type: none;
                padding-inline-start: 0 !important;

                li {
                    position: relative;
                    text-transform: capitalize;
                    //font-size: 0.8em !important;
                    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
                    font-weight: 200 !important;
                    color: #73E4CC;
                    padding-inline-start: 1.5em;
                    margin-bottom: 0.8em;

                    .border {
                        display: none;
                    }
                }

                li.selected {
                    text-transform: uppercase;
                    color: #C4FFF9;

                    .border {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 0.2em;
                        height: 1.5em;
                        background: $primary 0% 0% no-repeat padding-box;
                        border-radius: 0px 2px 2px 0px;
                    }
                }

                li:hover {
                    text-transform: uppercase;
                    color: #C4FFF9;

                    .border {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 0.2em;
                        height: 1.5em;
                        background: $primary 0% 0% no-repeat padding-box;
                        border-radius: 0px 2px 2px 0px;
                    }
                }
            }
        }

    }

    .Export-Wrapper{
        .table-export{
            display: flex;
            justify-content: center;
            align-self: flex-end;
            align-items: center;
            // background-color:#02312C ;
            // box-shadow: $dark-button-box-shadow ;
            -webkit-appearance: none !important;
            background-color: $container-background;
            $lower-shadow: 4px 4px 8px darken($container-background, 3%);
            $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
            box-shadow: $upper-shadow, $lower-shadow  !important;
            -webkit-box-shadow: $upper-shadow, $lower-shadow  !important;
            outline: none;
            border: none;
            width: 30px;
            height: 30px;
            padding: 5px;
            border-radius: 5px;
            margin-inline-start: 1em;
            @extend .neuo;
        }
        .table-export:hover {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 1px solid #C4FFF9;
            border-radius: 5px;
        }
    }


    .filter-view {
        width: calc(100% + 2rem);
        margin-top: 1em;
        padding-bottom: 1em;
        margin-inline-start: -2rem;
        // height:10em;
        background-color: #0A4640;
        border: 1px solid #46AAA0A7;
        border-radius: 5px;

        .filtering-tabs-view {
            display: flex;
            flex-direction: row;
            margin-inline-start: 1em;
            margin-top: 0.5em;

            .filter-button {
                background-color: transparent;
                outline: none;
                border: none;
                width: 8.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #46AAA0;
                font-size: 1em;
                border-radius: 0px;
                height: 2.5em
            }

            .filter-button:hover {
                text-transform: uppercase;
                color: #73E4CC;
            }
        }

        .list-view {
            display: flex;
            flex-direction: row;
            padding-inline-start: 1em;
            margin-top: 2em;
            overflow-y: scroll;
            flex-wrap: wrap;

            .Checkbox-Container {
                margin-inline-end: 1.5em;
                padding-block: 0.5em;

                .Checkbox-View {
                    background: #0A4640 0% 0% no-repeat padding-box;
                    box-shadow: $dark-button-box-shadow !important;
                }

                .Checkbox-Title {
                    margin-inline-start: 10px;
                    //font-size: 0.8em;
                    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
                    color: #73E4CC;
                    vertical-align: middle;
                    font-family: nunito_semibold;
                }
            }
        }

        .calendar-view {
            position: relative;

            .calendar-button {
                margin-inline-start: 2em;
                margin-top: 2em;
                background-color: #0D4944;
                box-shadow: $dark-button-box-shadow;
                color: #73E4CC;
                border: none;
                outline: none;
                border-radius: 5px;
                height: 2.5em;
                width: 18em;
                position: relative;
                padding-inline-start: 2.5em;
                padding-inline-end: 1em;
            }

            .calendar-button::before {
                content: url('../../assets/icons/svg/MainMap/calendar.svg');
                width: 2em;
                height: 1.5em;
                z-index: 1000 !important;
                position: absolute;
                left: 0.3em;
                top: 0.625em;
            }

            .triangle {
                position: absolute;
                width: 0;
                height: 0;
                top: 4.6em;
                left: 10em;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid $light-container-background;
                z-index: 1000 !important;
            }

            .calendar {
                position: absolute;
                left: 1.7em;
                top: 5.3em;
                z-index: 100 !important;

                .date-picker-container {
                    background-color: $light-container-background !important;
                    width: 18em;
                }
            }
        }

        .action-buttons-container {
            display: flex;
            justify-content: flex-end;
            padding-inline-end: 1em;

            button {
                outline: none;
                border: none;
                background-color: transparent;
            }

            .clear-items {
                color: #F48585;
                margin-inline-end: 0.2em;
            }

            .apply-filter {
                color: #73E4CC;
            }
        }
    }

}

[dir='rtl'] {
    .sorting-and-filtering-container {
        .calendar {
            right: 1.7em;
            left: inherit !important
        }
        .triangle {
            right: 0.5em;
        }
        .sorting-container .sorting-list-container {
            top: 3.2em;
            left: -1.5em;
        }
    }
    .filter-view .filtering-tabs-view .filter-button {
        width: 13em;
    }
}

@media (max-width:800px){
    .filtering-button, .sorting-button {
        width: 25px !important;
        height: 25px !important;
    }
}