.Export-Wrapper {
    .DayPicker-Months {
        background-color: #196A63 !important;
        font-size: 0.9rem !important;
        border-radius: 4px !important;
        height: 17.5rem;
        width: 17.5rem;
    }

    .Export-Container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 2em;
        height: 2em;
        -webkit-appearance: none !important;
        //box-shadow: -2px -2px 5px $action-button-box-shadow;
        //-webkit-box-shadow: -2px -2px 5px $action-button-box-shadow !important;
        border-radius: 5px;
        margin-inline-end: 0.5em;
        outline: none !important;

        &:hover {
            background-color: $primary;
        }

    }

    .calendar-button {
        position: relative;
        width: 13em;
        height: 2.5em;
        background: $calendar-background 0% 0% no-repeat padding-box;
        -webkit-appearance: none !important;
        box-shadow: $dark-button-box-shadow;
        -webkit-box-shadow: $dark-button-box-shadow !important;
        border-radius: 5px;
        outline: none !important;
        color: $primary;
        margin-top: 1rem;

        span {
            padding-inline-start: 1.7em;
            color: $primary;
            font-weight: 500;
            font-size: 0.92em
        }
    }

    .calendar-button::before {
        content: url('../../assets/icons/svg/MainMap/calendar.svg');
        width: 2em;
        height: 1.5em;
        z-index: 1000 !important;
        position: absolute;
        left: 0.3em;
        top: 0.75em;
    }

    .Calendar-Wrapper {
        position: absolute;
        padding-top: 20px;
        z-index: 5;

        .date-picker-container {
            width: 15rem;
            background-color: $light-container-background !important;
        }

        .triangle {
            position: absolute;
            width: 0;
            height: 0;
            top: 0.5em;
            left: 1.5em;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #196A63;
            z-index: 1000 !important;
        }
    }

    .Export-Modal-Title {
        @extend .gadget-title;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    }

    .Export-Modal-Content-subTitle {
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    }

    .Export-Modal-Content-Title {
        @extend .gadget-title;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    }

    .Seperator {
        border-top: 1px solid;
        width: 88%;
        opacity: 0.3;
        margin-top: 1rem;
        margin-bottom: 1rem;


    }

    .Export-Modal-Email-Wrapper {
        .input-group-prepend {
            white-space: nowrap;
            vertical-align: middle;
            // margin-top: 0.4rem;
        }
    }

    .Export-Modal-Option-Wrapper {
        display: flex;
        align-self: center;

        .Export-Modal-Option {
            &:hover {
                -webkit-appearance: none !important;
                background: #73E4CC 0% 0% no-repeat padding-box;
                box-shadow: -2px -3px 5px #0B837780;
                -webkit-box-shadow: -2px -3px 5px #0B837780 !important;
                border: 3px solid #C4FFF9;
                opacity: 1;
                cursor: pointer;

            }

            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 8.5em;
            height: 6em;
            -webkit-appearance: none !important;
            box-shadow: -2px -2px 5px #0b837780;
            -webkit-box-shadow: -2px -2px 5px #0b837780 !important;
            border-radius: 5px;
            margin-inline-end: 3.5em;
            outline: none !important;
        }

        .Export-Modal-Otion-Selected {
            display: flex;
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 3px solid #C4FFF9;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 8.5em;
            height: 6em;
            -webkit-appearance: none !important;
            box-shadow: -2px -2px 5px #0b837780;
            margin-inline-end: 3.5em;
            -webkit-box-shadow: -2px -2px 5px #0b837780 !important;
            outline: none !important;
            border-radius: 5px;
        }
    }

    .Export-Modal-Email-Wrapper {
        width: 80%;
        align-self: center;
    }

}



@mixin shadow($neuo) {
    -webkit-appearance: none;
    $lower-shadow: 4px 3px 10px darken($neuo, 6%);
    $upper-shadow: -3px -3px 5px lighten($neuo, 7%);
    box-shadow: $upper-shadow, $lower-shadow;
    -webkit-box-shadow: $upper-shadow, $lower-shadow;
}

.neuo {
    border-radius: 5px;
    border-style: hidden;
    background: $main_layer;
    @include shadow($main_layer);
    cursor: pointer;
}