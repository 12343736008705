.card-with-icon-container{
    position:relative;
    width:10em;
    padding-bottom:1em;
    border-radius:5px;
    border:1px solid $card-with-icon-border;
    background-color:$empty-state-graph;
    .icon-container{
        left:3em;
        top:-1.5em;
        position:absolute;
        width:3.5em;
        height:3.5em;
        border-radius:1.75em;
        background-repeat: no-repeat;
        background-size: 2em 2em;
        background-position: center center;
        border: 2px solid $secondary-txt;
    }
}