.VegetationIndex_Container {

    .map-loader {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #02312CA6 0% 0% no-repeat padding-box;
        z-index: 1000;

        .Loading {
            height: 100% !important;
        }

        .loading-text {
            margin-inline-start: 8px;
            font-size: 1em;
            color: #73E4CC;
        }
    }

    ::-webkit-scrollbar {
        height: 7px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background: transparent;

    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #256F68;
        border-radius: 10px;
        width: 2px !important;
        cursor: pointer !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #348C83;

    }

    ::-webkit-scrollbar {
        display: inline-block !important;
    }

    .temp-wrapper {
        display: flex;
        flex-direction: row-reverse;
        height: 100px;
        align-items: center;
        width: 100%;
        overflow-x: scroll;
        background-color: black;

        .item {
            flex: 0 0 auto;
            background-color: white;
            margin-inline: 5px;
            width: 120px;
            height: 80px
        }
    }

    #Cardbody_VegetationMap {
        height: 100%;
        width: 100%;
        position: relative;
        padding: 0px;

        .plot-info-wrapper {

            .info-icon {
                border: none !important;
                outline: none !important;
                position: absolute;
                inset-inline-end: 25px;
                //bottom: 15px;
                bottom: 2.5rem;
                background-color: #03403A;
                border-radius: 5px;
                // width: 3em;
                // height: 3em;
                width: 42px;
                height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 25px;
                }

                &:hover {
                    background-color: #73E4CC;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        top: 1.2em;
                        border-bottom: 7px solid transparent;
                        border-top: 7px solid transparent;
                        border-left: 7px solid #003A35CC;
                        inset-inline-start: -10px;
                    }

                    .info-tooltip {

                        background-color: #003A35CC;
                        border-radius: 3px;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 5px;
                        height: 30px;
                        width: 100px;
                        inset-inline-start: -108px;
                        color: #73E4CC
                    }
                }

                .tooltip-wrapper {
                    position: absolute;
                }
            }
        }

        .color-map-wrapper {

            .info-icon {
                inset-inline-start: 11px;
                bottom: 55px;
                // width: 50px;
                // height: 40px !important;
                width: 42px;
                height: 42px;
                border: none !important;
                outline: none !important;
                position: absolute;
                background-color: #03403A;
                border-radius: 5px;
                //height: 3em;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 25px;
                }

                &:hover {
                    background-color: #73E4CC;

                    svg {
                        path {
                            stroke: #03403a;
                            stroke-width: 1
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        top: 1.2em;
                        border-bottom: 7px solid transparent;
                        border-top: 7px solid transparent;
                        border-right: 7px solid #003A35CC;
                        inset-inline-start: 51px;
                    }

                    .info-tooltip {
                        top: 5px;
                        inset-inline-start: 57px;
                        background-color: #003A35CC;
                        border-radius: 3px;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        height: 30px;
                        width: 100px;
                        color: #73E4CC
                    }
                }

                .tooltip-wrapper {
                    position: absolute;
                }
            }

            .color-map-container {
                position: absolute;
                min-width: 8.5rem;
                background-color: #03403A;
                border-radius: 5px;
                color: #C4FFF9;
                bottom: 3.5rem;
                inset-inline-start: 0.8rem;
                padding-inline-start: 1rem;
                padding-block-start: 8px;


                .color-map-title {
                    text-transform: uppercase;
                    padding-block: 5px;
                    font-size: 0.9em;

                    .minimize-button {
                        position: absolute;
                        top: 6px;
                        inset-inline-end: 7px;

                        svg {
                            width: 1.7em;
                            height: 1.7em;
                        }
                    }
                }

                ul {
                    padding-inline-start: 0 !important;
                    padding-block-start: 8px;
                    padding-inline-end: 20px;
                    height: 12rem;
                    overflow: scroll;

                    .color-row {
                        margin-block-end: 5px;
                        display: flex;
                        align-items: center;

                        .color-value {
                            min-width: 2.1em;
                        }

                        .seperator {
                            color: #46AAA0;
                            margin-inline-start: 5px;
                            margin-inline-end: 8px;
                            font-family: nunito_extraBold;
                        }

                        .color {
                            display: inline-block;
                            width: 14px;
                            height: 13px;
                            border: 1px solid #03403A;
                            border-radius: 3px;
                            margin-inline-end: 8px;
                        }
                    }
                }
            }
        }

        .filter-buttons-container {
            position: absolute;
            top: 8rem;
            inset-inline-end: 25px;
            display: flex;
            flex-direction: row;

            .index-selector:not(.selected):hover {
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 100%;
                    border-right: 7px solid transparent;
                    border-left: 7px solid transparent;
                    border-bottom: 7px solid #003A35CC;
                    inset-inline-start: 30%;
                }

                .index-tooltip {

                    background-color: #003A35CC;
                    border-radius: 3px;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 115%;
                    height: 30px;
                    min-width: 100px;
                    white-space: nowrap;
                    padding-inline: 5px;
                    inset-inline-start: 10% !important;
                    color: #73E4CC
                }
            }

            .drop-down-selector-container,
            .quarter-selection {
                width: 7.9em;
                height: 3em !important;
                border-radius: 5px;
                background-color: #03403A;
                border: none !important;
                outline: none !important;
                color: #73E4CC;
                box-shadow: 3px 3px 6px #001A17A2;

                .quarter-icon {
                    padding-inline-end: 5px;

                    svg {
                        margin-block-start: -2px
                    }
                }
            }

            .drop-down-selector-container:hover,
            .quarter-selection:hover {
                background-color: #73E4CC;

                .selected-quarter,
                .current-choice {
                    color: #165F59;
                }

                .current-choice {

                    &:hover::before {
                        content: url('../../assets/icons/svg/sidebar/active/vegetationIndexactive.svg');
                        padding-top: 5px;
                        padding-inline-end: 5px;
                    }
                }
            }

            .quarter-selector {
                .triangle {
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 3em;
                    inset-inline-start: 50%;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid #165F59;
                    z-index: 1000 !important;
                }

                .quarter-picker-wrapper {
                    position: absolute;
                    top: 125%;
                    inset-inline-start: -100%;
                }

                .quarter-selection {
                    margin-inline-start: 15px;
                }

                position: relative;
            }

            .index-selector {
                margin-inline-start: 5px;

                .triangle {
                    top: 100%;
                    left: 40%;
                }

                .choices-list {
                    top: 120%;
                    inset-inline-start: -7% !important;
                    width: 8em !important;
                }

                .drop-down-selector-container {
                    -webkit-box-shadow: none !important;

                    .current-choice {
                        &::before {
                            content: url('../../assets/icons/svg/sidebar/inactive/vegetationIndexInactive.svg');
                            padding-top: 5px;
                            padding-inline-end: 5px;
                        }
                    }
                }
            }

            .index-selector.selected,
            .quarter-selector.selected {

                .drop-down-selector-container,
                .quarter-selection {
                    background-color: #73E4CC;

                    .selected-quarter,
                    .current-choice {
                        color: #165F59;
                    }

                    .current-choice::before {
                        content: url('../../assets/icons/svg/sidebar/active/vegetationIndexactive.svg');
                        padding-top: 5px;
                        padding-inline-end: 5px;
                    }
                }
            }
        }
    }

    #comparison-container {
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
        align-items: center;
        margin: 0px;
        padding: 0px;

        .BeforeMap {
            width: 50%;
            height: 100%;
            position: relative;
            border-radius: 5px 0px 0px 5px;
            margin-inline-end: 2.5px;
        }

        .AfterMap {
            width: 50%;
            height: 100%;
            position: relative;
            border-radius: 0px 5px 5px 0px;
            margin-inline-start: 2.5px;
        }
    }

    .Map-Button-Container .Farm-Button {
        &:hover {
            border-radius: 8px;
            cursor: pointer;
            background-color: #73E4CC;

            p {
                color: #03403A
            }
        }

        height: 40px;
        background-color: #03403A;
        flex: 1 1;
        position: absolute;
        top: 14em;
        inset-inline-start: 0.4rem;
        right: 1;
        transition: all 0.2s ease-out;
        border-radius: 8px;
    }

    // .Farm-Content {
    //     &::before {
    //         content: '';
    //         position: absolute;
    //         width: 0;
    //         height: 0;
    //         top: 1.2em;
    //         //transform: translateX(-360%) translateY(-1px) rotate(40deg);
    //         border-bottom: 10px solid transparent;
    //         border-top: 10px solid transparent;
    //         border-right: 10px solid #003A35CC;
    //         // bottom: 0;
    //         inset-inline-start: 2rem;
    //     }

    //     p {
    //         margin-top: 15px;
    //         ;
    //     }

    //     background-color:#003A35CC;
    //     border-radius:3px;
    //     flex: 1;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     position: absolute;
    //     top: 14.2em;
    //     height: 30px;
    //     width: 100px;
    //     // bottom: 1em;

    //     left: 4rem;
    //     right: 1;
    // }

    .SplitButton_Container {
        display: flex;

        .SplitViewSelected_Button {
            &:hover {
                cursor: pointer;
            }

            position: absolute;
            top: 11.2rem;
            bottom: 0px;
            inset-inline-start: 0.5rem;
            justify-content: center;
            align-items: center;
            // width: 3rem;
            // height: 3rem;
            width:42px;
            height:42px;
            border-radius: 5px;
            background-color: #73E4CC;
            display: flex;
            flex: 1;

            svg {
                width: 20px;
            }
        }

        .SplitView_Button {
            &:hover {
                background-color: #73E4CC;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 1.2em;
                    border-bottom: 7px solid transparent;
                    border-top: 7px solid transparent;
                    border-right: 7px solid #003A35CC;
                    inset-inline-start: 3rem;
                }
            }

            position: absolute;
            top: 11.2rem;
            bottom: 0px;
            inset-inline-start: 0.5rem;
            justify-content: center;
            align-items: center;
            // width: 3rem;
            // height: 3rem;
            width: 42px;
            height: 42px;
            border-radius: 5px;
            background-color: #03403A;
            display: flex;
            flex: 1;

            svg {
                width: 20px;
            }
        }

        .Split_Content {


            p {
                margin-top: 15px;
            }

            background-color: #003A35CC;
            border-radius:3px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 11.2rem;
            height: 30px;
            min-width: 100px;
            white-space: nowrap;
            padding-inline: 5px;
            inset-inline-start: 3.9rem;
        }

        .Link-Button,
        .Unlink-Button {
            top: 7rem;
            position: absolute;
            /* top: 11.2rem; */
            // inset-inline-start: 4.5rem;
            justify-content: center;
            align-items: center;
            // width: 3rem;
            // height: 3rem;
            width: 42px;
            height: 42px;
            border-radius: 5px;
            background-color: #03403A;
            display: flex;
            flex: 1 1;

            &:hover {
                background-color: #73E4CC;
            }

            svg {
                width: 21px;
            }
        }

        .Link-Button {
            inset-inline-start: 3.8rem;

            &:hover {
                background-color: #73E4CC;

                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 50px;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid #003A35CC;
                    inset-inline-start: 18px;
                }

                .link-tooltip {

                    background-color: #003A35CC;
                    border-radius: 3px;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 5px;
                    height: 30px;
                    width: 100px;
                    inset-inline-start: -1.5rem;
                    color: #73E4CC;

                    p {
                        margin-block: 0 !important;
                    }
                }

            }
        }

        .Unlink-Button {
            inset-inline-start: 7.1rem;

            &:hover {
                background-color: #73E4CC;

                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 50px;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid #003A35CC;
                    inset-inline-start: 18px;
                }

                .unlink-tooltip {

                    background-color: #003A35CC;
                    border-radius: 3px;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 5px;
                    height: 30px;
                    width: 100px;
                    inset-inline-start: -1.5rem;
                    color: #73E4CC;

                    p {
                        margin-block: 0 !important;
                    }
                }

            }

            svg {
                width: 24px;
            }
        }
    }



    .mapboxgl-popup {
        // &::before {
        //     position: absolute;
        //     top: 105%;
        //     left: 50%;
        //     content: '';
        //     width: 10px;
        //     height: 10px;
        //     border-radius: 10px;
        //     background-color: transparent;
        //     border: 2px solid #fff
        // }
        margin-top: -20px;
    }

    .mapboxgl-popup-content {
        font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', sans-serif;
        padding: 0;
        max-width: 150px;
        min-width: 110px;
        min-height: 70px;
        max-height: 70px;
        border-radius: 10px;

    }

    .DayList-Container {
        flex-wrap: wrap;
        width: 97%;
        cursor: grab;
        overflow-x: scroll;


        ul {
            list-style-type: none;
            display: flex;

            .slide {
                position: relative;
            }
        }

        .DayListItem_Container {
            &:not(.cloudy):hover {
                background: #73E4CC 0% 0% no-repeat padding-box;
                border: 2px solid #C4FFF9;
                border-radius: 5px;
                opacity: 1;
            }

            min-width: 100px;
            min-height: 60px;
            background: #1F847A 0% 0% no-repeat padding-box;
            border: 1px solid #46AAA0;
            border-radius: 5px;
            opacity: 1;
            margin-top: 8px;
            margin-inline-start: 10px;

            .card-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                position: relative;

                p {
                    //font-size: 0.6rem;
                    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
                    text-align: center;
                    margin-inline-start: 5px;
                    margin-bottom: 5px !important;
                }

                .calendar-icon {
                    svg {
                        width: 1.8em;
                        height: 1.4em;
                    }

                    margin-block: 5px;
                }

            }

            .tooltip-wrapper {
                // position: absolute;
                background: #003A35E6 0% 0% no-repeat padding-box;
                border-radius: 3px;
                width: 100px;

                .tooltiptext {
                    // position: initial;
                    background-color: transparent;
                    width: 100%;
                }
            }

        }

        .DayListItemSelected_Container {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 2px solid #C4FFF9;
            font-family: 'nunito_extraBold';
        }

        .cloudy {
            background: #205750BE 0% 0% no-repeat padding-box;
            border: 1px solid #46AAA059;

            &:hover {
                border: 1px solid #46AAA0D9;
            }
        }
    }




    .issue_toggle_buttonSplit {
        padding: 0 !important;
        margin-inline-start: 46%;
        background-color: $primary !important;
        width: 65px !important;
        height: 18px !important;
        position: absolute !important;
        border-radius: 0px 0px 5px 5px !important;
        border-color: transparent !important;
        outline: none;
        box-shadow: none;
        opacity: 1 !important;
    }
}

@media (max-width: 878px) {
    #comparison-container {
        flex-direction: column !important;

        .BeforeMap,
        .AfterMap {
            width: 100% !important;
        }

        .AfterMap {
            margin-top: 10px;
        }
    }
}

[dir='rtl'] {
    .VegetationIndex_Container {
        .color-map-wrapper {

            .info-icon {

                &::after {
                    content: '';
                    border-top: 7px solid transparent;
                    border-right: none !important;
                    border-left: 7px solid #003A35CC;
                }

            }
        }

        .SplitView_Button {
            &:hover {
                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 1.2em;
                    border-bottom: 7px solid transparent;
                    border-top: 7px solid transparent;
                    border-right: none !important;
                    border-left: 7px solid #003A35CC;
                    inset-inline-start: 3rem;
                }
            }
        }

        .DayList-Container .DayListItem_Container .card-wrapper p {
            font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (2800 - 320)));
        }

        #Cardbody_VegetationMap .filter-buttons-container {
            .quarter-selection {
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            }

            .quarter-selector {
                .triangle {
                    top: 3.3em;
                }

                .quarter-picker-wrapper {
                    position: absolute;
                    top: 125%;
                    inset-inline-start: -130%;
                }
            }
        }

        #Cardbody_VegetationMap .color-map-wrapper .color-map-container .color-map-title {
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        }
    }
}