.view-all-meters-screen {
    .card {
        height: 54em !important;
    }

    .meters-list-card {
        .card-body {
            padding-inline: 0.7rem !important;

            .meter-list-wrapper {

                height: 100%;
                position: relative;

                .pagination-container {
                    flex-wrap: wrap;
                    inset-inline-start: 0em !important;
                    width: 55% !important;
                }

                .pagination-item {
                    margin: auto 0px !important;
                }

                .meters-list-container {
                    list-style: none;
                    padding-inline-start: 0 !important;
                    height: 87%;
                    overflow-y: scroll;
                    & ~ a{
                        font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
                        color:#73E4CC;
                    }


                    li {
                        margin-bottom: 15px;
                        background: #024842 0% 0% no-repeat padding-box;
                        border-radius: 5px;
                        padding-block: 15px !important;
                        padding-inline: 20px !important;

                        .action-buttons-container {
                            display: flex;
                            flex-direction: row;

                            button {
                                background-color: #024842;
                                margin-inline-end: 10px;
                                box-sizing: border-box;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                flex-direction: row;
                                align-items: center;
                                width: 28px;
                                height: 28px;
                                outline: none;
                                border: none;

                                svg {
                                    width: 18px;
                                    height: 18px;
                                }
                            }

                            .update {
                                svg {
                                    width: 22px;
                                    height: 22px;
                                }
                            }

                            button:hover {
                                background: #73E4CC 0% 0% no-repeat padding-box;
                                box-shadow: -2px -2px 5px #46AAA040;
                                border: 2px solid #C4FFF9;
                                border-radius: 5px;
                            }
                        }

                        .info-container {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            font-size:calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
                            .meter-item {
                                margin-inline-end: 20px;
                                min-width: 100px;
                                margin-bottom: 10px;

                                .status {
                                    color: #FF7D7D
                                }

                                .meter-key {
                                    color: #C4FFF9;
                                }

                                .meter-value {
                                    color: #46AAA0;
                                }
                            }
                        }
                    }

                    .selected {
                        border: 2px solid #46AAA0;
                    }
                }

                .meter-list-count {
                    position: absolute;
                    bottom: 1rem;
                    inset-inline-end: 0;
                    width: 45%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    font-size: 0.95em;
                }

                a:hover {
                    text-decoration: underline !important;
                }
            }


        }
    }


    .col-lg-3 {
        padding-right: 0 !important;
        padding-left: 6px !important;
    }

    .col-lg-9 {
        padding-right: 6px !important;
    }

    .modal-body-container {
        .delete-icon {
            margin-bottom: 15px;
        }

        .delete-header {
            color: #C4FFF9
        }

        .delete-subheader {
            width: 250px;
            text-align: center;
            margin-bottom: 15px;
        }
    }

}