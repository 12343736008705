.color-picker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline-start: auto;
    margin-inline-end: 30px;

    .field-name {
        margin-inline-end: 5px;
        font-size: 1em;
        font-weight: 600;
        letter-spacing: 0px;
        color: #46AAA0;
    }

    .color-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3em;
        height: 2em;
        border-radius: 5px;
        background-color: #165F59;
        $lower-shadow: 4px 4px 6px darken(#165F59, 5%);
        $upper-shadow: -2px -2px 2px lighten(#165F59, 3%);
        box-shadow: $upper-shadow, $lower-shadow !important;
        border: none !important;
        outline: none !important;
        position: relative;

        .triangle {
            position: absolute;
            width: 0;
            height: 0;
            top: 2.1em;
            left: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #03403A;
            z-index: 1000 !important;
        }

        .sample-chart-div {
            background-color: #02312C;
            border-radius: 5px;
            position: absolute;
            top: 21.5em;
            padding-inline: 5px;
            width: 13em;
            left: -11em;
            height: 5em;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1001 !important;
        }

        .apply-button {
            position: absolute;
            top: 27em;
            width: 7em;
            height: 2.5em;
            left: -7.5em;
            z-index: 1001 !important;
            border-radius: 5px;
            outline: none !important;
            border: none;
            font-weight: 700;
            color: #165f59;
            background-color: #73e4cc;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .proceed-icon {
            margin-inline-start: 0.5em;
        }

        // .apply-button::after{
        //     content: url('../../assets/icons/svg/calendar/proceed_EN.svg');
        //     width: 60px;
        //     height: 30px;
        //     margin-top:1em;
        //     padding-inline: 0.3em;
        // }
        .w-color-sketch {
            position: absolute;
            left: -12em;
            top: 28px;
            z-index: 1000;

            .w-color-editable-input {
                flex-direction: row !important;
                margin-block: 5px;
                &::before {
                    content: 'hex';
                    position: absolute;
                    width: 20px;
                    top: -8px;
                    color: #73e4cc;
                }

                span {
                    display: none !important;
                }

                input {
                    background-color: transparent;
                    color: #C4FFF9;
                    margin-top: 6px;
                    box-shadow: none !important;
                    border-bottom: 2px solid #C4FFF92E !important;
                    outline: none !important;
                    position: relative;

                    &:focus {
                        border-color: $primary;
                    }

                }

                // span{
                //     color: #46AAA0 !important;
                //     margin-inline-start: 5px !important;
                // }
            }

            .w-color-editable-input-rgba {
                display: none !important;
            }

            .w-color-saturation {
                margin-top: -20px !important;
                margin-bottom: -20px !important;
                transform: scaleY(0.7);
            }
        }

        .color {
            width: 2em;
            height: 1em;
            border-radius: 2px;
        }
    }
}

[dir='rtl'] .color-picker-container {
    .triangle {
        right: 0;
        left: inherit !important
    }

    .w-color-sketch {
        right: -12em;
        left: inherit !important
    }

    .sample-chart-div {
        right: -11em;
        left: 0 !important
    }

    .apply-button {
        right: -7.5em;
        left: 0 !important
    }
}