form .input-group {
  position: relative;
  width: 100%;
  margin: 30px 0;

  .form-control {
    position: relative;
    border: none;
    outline: none;
    width: 100%;
    color: $primary-txt !important;
    font-size: 14px;
    padding: 8px 0;
    box-sizing: border-box;
    border-radius: 0 !important;
    border-bottom: 2px solid #C4FFF92E;
    background: transparent;
    z-index: 2;
    filter: none !important;

    &::placeholder {
      color: transparent !important;
    }

    &:focus {
      border-color: $primary;
      caret-color: $primary ;
      box-shadow: none !important;

      &::placeholder {
        opacity: -1 !important;
      }
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s !important;
      -webkit-text-fill-color: $primary-txt !important;
    }

    &:-webkit-credentials-auto-fill-button:hover {
      -webkit-box-shadow: 5px 5px $primary inset !important;
      box-shadow: 5px 5px $primary inset !important;
    }
  }

  span {
    display: inline-block;
    position: absolute;
    left: 0;
    color: $primary-txt;
    font-size: 1em;
    z-index: 1;
    transition: .5s;
  }
}

form .input-group .form-control+span {
  bottom: 16%;
  left: 10%;
}

form .input-group .form-control:focus+span {
  color: $primary;
  bottom: 80%;
  left: 0;
  //font-size: 0.75em;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
}

.input-group-prepend {
  position: absolute;
  top: 12%;
  left: 0;

  .input-group-text {
    background-color: none !important;
    border-color: none !important;
    border: none !important;
    background: none !important;
    color: $primary-txt !important;
  }
}

[dir='rtl'] .input-group-prepend {
  left: inherit !important;
  right: 0
}

form .input-group .form-control:focus~.input-group-prepend {
  display: none;
}

form .input-group .form-control:not(:placeholder-shown)~.input-group-prepend {
  display: none;
}

.input-container {
  background: none !important;
  background-color: none !important;
}

form .input-group {
  position: relative !important;
  width: 100% !important;
  margin: 30px 0;

  .form-control {
    position: relative !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: none !important;
    width: 100% !important;
    color: $primary-txt ;
    //font-size: 14px!important;
    font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (2800 - 320)));
    padding: 8px 0 !important;
    box-sizing: border-box !important;
    border-radius: 0 !important;
    border-bottom: 2px solid;
    border-bottom-color: #C4FFF92E;
    background: transparent !important;
    background-color: transparent !important;
    z-index: 2;

    &::placeholder {
      color: transparent !important;
    }

    &:focus {
      border-bottom-color: $primary;
      caret-color: $primary !important;
      box-shadow: none !important;

      &::placeholder {
        opacity: -1 !important;
      }
    }
  }

  span {
    display: inline-block !important;
    position: absolute !important;
    //font-size: 14px!important;
    font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (2800 - 320)));
    z-index: 1 !important;
    transition: .5s !important;
  }
}

form .input-group .form-control+span {
  bottom: 16% !important;
}

form .input-group .form-control+span {
  inset-inline-start: 10% !important
}

form .input-group .form-control:focus+span {
  color: $primary ;
  bottom: 80% !important;
  //@extend .link;
}

.padding-bottom-20 {
  padding-bottom: 20;
}

.error-color {
  color: #F48585 !important;
}

.focus-color {
  color: #73E4CC;
}

.default-color {
  color: #C4FFF9;
}

.default-input-focus-padding-inline {

  //padding-inline-start: 0.8em;
  @media (max-width: 600px) {
    padding-inline-start: 1.5em;
  }
}

[dir=rtl] .default-input-focus-padding-inline {
  @media (min-width: 950px) and (max-width: 1100px) {
    padding-inline-start: 1.2em;
  }
}

[dir=ltr]form .input-group .form-control:focus+span,
input:-webkit-autofill+span,
input:-webkit-autofill:hover+span,
input:-webkit-autofill:focus+span,
input:-webkit-autofill:active+span {
  left: 0 !important;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
  text-transform: lowercase;
}

[dir=rtl]form .input-group .form-control:focus+span,
input:-webkit-autofill+span,
input:-webkit-autofill:hover+span,
input:-webkit-autofill:focus+span,
input:-webkit-autofill:active+span {
  right: 0 !important;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
  text-transform: lowercase;
}

.input-group-prepend {
  position: absolute !important;
  top: 12%;

  .input-group-text {
    background-color: none !important;
    border-color: none !important;
    border: none !important;
    background: none !important;
    color: $primary-txt ;
  }
}

.input-group-prepend {
  inset-inline-start: 0 !important
}

form .input-group .form-control:focus~.input-group-prepend,
input:-webkit-autofill~.input-group-prepend,
input:-webkit-autofill:hover~.input-group-prepend,
input:-webkit-autofill:focus~.input-group-prepend,
input:-webkit-autofill:active~.input-group-prepend {
  display: none;
}

form .input-group .form-control:not(:placeholder-shown)~.input-group-prepend {
  display: none;
}


form .input-group .form-control:not(:placeholder-shown)~span {
  color: $primary;
  bottom: 80% !important;
  //font-size: 12px !important;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
}

form .input-group .form-control:not(:placeholder-shown)~span {
  inset-inline-start: 0 !important;
  text-transform: lowercase;
}


[dir='rtl'] form .input-group span {
  font-weight: 400;
}
