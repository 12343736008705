.DayInfo_Container {
    background: #03403AF2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding-top: 20px;
    opacity: 1;
    height: 22rem;
    position: absolute;
    top: 11rem;
    inset-inline-end: 15px;

    .summary-container {
        position: relative;
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 500;
            //font-size: 0.9em;
            font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
        }

        .subtitle {
            .cloudiness-title {
                margin-inline-start: 5px
            }

            .cloudiness-reading {
                color: #C4FFF9;
            }
        }

        .count-wrapper {
            text-align: center;
            margin-top: 10px;

            .count {
                font-size: 1.3em;
                color: #C4FFF9
            }

        }

        .minimize-button {
            position: absolute;
            inset-inline-end: 5px;

            svg {
                width: 1.7em;
                height: 1.7em;
            }
        }
    }

    .line-seperator {
        width: 80%;
        border-bottom: 1px solid #46AAA080;
        opacity: 0.5;
        margin-inline: auto;
        margin-block: 10px;
    }

    .DayInfoValues_Container {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-inline: 20px;
        height: 72%;
        overflow: scroll;
        padding-block: 10px;
        margin-top: 20px;

        .DayInfoValues_Wrapper {
            display: flex;
            flex: 1;
            justify-content: space-around;
            width: inherit;

            .DayInfo_Values {
                text-align: center;

                .Value_Reading {
                    color: $primary-txt;
                    font-weight: 500;
                    font-size: 1.3rem;
                }
                .Value_Title {
                    color: #46AAA0;
                    font-size: 0.7rem;
                    margin-top: -1rem;
                }
                .Value_Difference--dec {
                    color :#FF5B33;
                    font-size: 0.8rem;
                    margin-top: -1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .minus{
                        width: 7px;
                        height: 2px;
                        background: #FF5B33;
                        margin-inline-end: 2px;
                    }
                    &::before{
                        content: '';
                        display: inline-block;
                        $border: 7px;
                        border-left: $border solid transparent;
                        border-right: $border solid transparent;    
                        border-top: 8px solid #FF5B33;
                        margin-inline-end: .4rem
                    }
                }
                .Value_Difference--inc {
                    color :#7BC792;
                    font-size: 0.8rem;
                    margin-top: -1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .plus{
                        font-size: 0.9rem;
                        color: #7BC792;
                        margin-inline-end: 2px;
                    }
                    &::before{
                        content: '';
                        display: inline-block;
                        $border: 7px;
                        border-left: $border solid transparent;
                        border-right: $border solid transparent;    
                        border-bottom: 8px solid #7BC792;
                        margin-inline-end: .4rem
                    }
                }
                .Value_Difference--zero {
                    color :#7BC792;
                    font-size: 0.8rem;
                    margin-top: -1.4rem;
                    .segment{
                        display: inline-block;
                        width: 5px;
                        height: 1px;
                        background: #7BC792;
                    }
                }
            }
        }
    }

}

[dir='rtl'] {
    .DayInfo_Container {
        .summary-container {
            .title {
                @extend .title-ar;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            }

            .subtitle .cloudiness {

                &-title,
                &-reading {
                    font-size: calc(13px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
                }
            }
        }

        .DayInfoValues_Container .DayInfoValues_Wrapper .DayInfo_Values .Value_Title {
            font-size: calc(13px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
        }
    }

}