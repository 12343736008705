.farm-registration-wrapper {
    display: flex;
    flex-direction: row;
    height: 100vh;

    .modal-body-container {
        .cancel-icon {
            width: 10rem;
            height: 10rem;
        }

        .cancel-header {
            color: #C4FFF9;
            text-transform: uppercase;
            font-size: 0.9rem;
        }

        .cancel-subheader {
            color: #46AAA0;
            font-size: 0.9rem;
            padding-inline: 5rem;
            text-align: center;
        }
    }

    .farm-registration-map-container {
        z-index: 100;
        width: 72vw;
        height: 100vh;
        position: relative;

        .mapboxgl-ctrl-top-left {
            top: 30%;

            .mapboxgl-ctrl-group {
                overflow: visible;
                box-shadow: none;
                background: transparent;

                &:before {
                    content: "Zooming Tools";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-inline: 5px;
                    background-color: #165F5999;
                    color: #F2FFFE;
                    font-size: 0.8rem;
                    writing-mode: vertical-lr;
                    transform: rotate(180deg) translate(2rem, -5.3rem);
                }

                .mapbox-gl-draw_ctrl-draw-btn,
                .mapboxgl-ctrl-compass {
                    display: none;
                }

                &:first-child {
                    position: absolute;
                    inset-inline-start: 2rem;

                    .mapboxgl-ctrl-zoom-in,
                    .mapboxgl-ctrl-zoom-out {
                        width: 2rem;
                        height: 2rem;
                        margin-bottom: 10px;
                        background: #03403A 0% 0% no-repeat padding-box;
                        border-radius: 5px;
                        border: none;
                        outline: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                    }

                    .mapboxgl-ctrl-zoom-in:before {
                        content: "+";
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.7rem;
                        color: #73e4cc;
                    }

                    .mapboxgl-ctrl-zoom-out:before {
                        content: "−";
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.7rem;
                        color: #73e4cc;
                    }
                }
            }
        }

        .mapboxgl-ctrl-top-right {
            top: 40%;

            .mapboxgl-ctrl-group {
                overflow: visible;
                box-shadow: none;
                background: transparent;

                // &:after {
                //     content: "Drawing Tools";
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                //     padding-inline: 5px;
                //     background-color: #165F5999;
                //     color: #F2FFFE;
                //     font-size: 0.8rem;
                //     white-space: nowrap;
                //     // writing-mode: vertical-lr;
                //     transform: rotate(90deg) translate(-5rem, -0.5rem);
                // }

                .mapbox-gl-draw_ctrl-draw-btn,
                .mapboxgl-ctrl-compass {
                    display: none;
                }

            }
        }



        .shapes-buttons-container {
            position: absolute;
            display: flex;
            inset-inline-end: -1.5rem; // top: 50%;
            flex-direction: column;
            visibility: hidden;

            &:after {
                content: "Drawing Tools";
                display: flex;
                justify-content: center;
                align-items: center;
                padding-inline: 5px;
                background-color: #165F5999;
                color: #F2FFFE;
                font-size: 0.8rem;
                white-space: nowrap;
                // writing-mode: vertical-lr;
                transform: rotate(90deg) translate(-5rem, -0.5rem);
            }

            button {
                width: 2rem;
                height: 2rem;
                margin-bottom: 10px;
                background: #03403A 0% 0% no-repeat padding-box;
                border-radius: 5px;
                border: none;
                outline: none;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .triangle,
                .animation-tooltip {
                    display: none;
                }


                svg {
                    width: 1rem;
                    height: 1rem;
                }

                &:disabled {
                    opacity: 0.4;
                }

            }
            .selected:not(:disabled) {
                background: #73E4CC 0% 0% no-repeat padding-box;
                border: 2px solid #C4FFF9;

                #circularDef,
                #RectangularDef {
                    fill: #165F59;
                }

                #Path_3936,
                #Ellipse_1321,
                #Ellipse_1325,
                #Ellipse_1322,
                #Ellipse_1323,
                #Ellipse_1324 {
                    fill: #165f59;
                    stroke: #73e4cc;
                }
            }

            button:not(:disabled):hover {
                // background: #73E4CC 0% 0% no-repeat padding-box;
                // border: 2px solid #C4FFF9;
                @extend .selected;

                .triangle,
                .animation-tooltip {
                    display: block;
                }

                .triangle {
                    position: absolute;
                    top: 0.3rem;
                    inset-inline-start: -0.9rem;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 10px solid #C4FFF9;
                }

                .animation-tooltip {
                    position: absolute;
                    width: 12rem;
                    inset-inline-start: -12.9rem;
                    background-color: #156E67;
                    border-radius: 5px;
                    border: 1px solid #C4FFF9;

                    .text {
                        background-color: #165F59;
                        font-size: 0.8em;
                        color: #C4FFF9;
                        padding: 10px;
                        text-align: start;
                    }
                }

                &:before {
                    content: "";
                    top: 0.4rem;
                    inset-inline-start: -0.87rem;
                    position: absolute;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                    border-left: 8px solid #165F59;
                    z-index: 1002;
                }

                // #circularDef,
                // #RectangularDef {
                //     fill: #165F59;
                // }

                // #Path_3936,
                // #Ellipse_1321,
                // #Ellipse_1325,
                // #Ellipse_1322,
                // #Ellipse_1323,
                // #Ellipse_1324 {
                //     fill: #165f59;
                //     stroke: #73e4cc;
                // }
            }
            .contact-us-wrapper{
                margin-top: 7rem;
                margin-inline-start: 0.5rem;
                button{
                    &:hover{
                        .triangle,
                        .animation-tooltip {
                            display: none !important;
                        }
                    }
                    &:before {
                        content: "";
                        top: 0.4rem;
                        left: -0.87rem;
                        display: none;
                    }
                }
            }
        }

        .badge-wrapper {
            position: absolute;
            inset-inline-end: 2rem;
            top: 2rem
        }

        .mapboxgl-ctrl-geocoder {
            margin-block-start: 2rem;
            margin-inline-start: 2rem;

            // .suggestions {
            //     a:hover {
            //         background-color: #73E4CC40 !important;
            //     }
            // }

            .mapboxgl-ctrl-geocoder--icon {
                fill: #07403A;
            }

            .mapboxgl-ctrl-geocoder--powered-by {
                display: none !important;
            }

            .mapboxgl-ctrl-geocoder--input {
                border-radius: 3px;
                border: 2px solid #165F59;
                color: #07403A;
                font-weight: 500;
                font-size: 0.8em;
                background: #FFFFFFE6 0% 0% no-repeat padding-box;
                font-family: nunito_bold;

                &::placeholder {
                    color: #07403A;
                    font-weight: 500;
                }
            }



            .suggestions {
                top: 100% !important;
                background: #FFFFFFE6 0% 0% no-repeat padding-box !important;
                border: 2px solid #165F59 !important;
                border-radius: 0px 0px 5px 5px !important;

                .mapboxgl-ctrl-geocoder--suggestion {
                    padding-top: 10px !important;
                    border-top: 1px solid #07403A4D !important;
                }

                li:first-child {
                    .mapboxgl-ctrl-geocoder--suggestion {
                        border-top: 0 none !important;
                    }
                }

            }






            .mapboxgl-ctrl-geocoder--suggestion-title {
                color: #07403A;
                display: flex;
                flex-direction: row;

                &::before {
                    content: url('../../assets/icons/svg/constants/loc.svg');
                    padding-inline-end: 5px;
                    transform: scale(0.8);
                }
            }

            .mapboxgl-ctrl-geocoder--suggestion-address {
                color: #167A6F;
            }
        }
    }



    .farm-registration-panel-container,
    .farm-registration-panel-container .Panel {
        width: 28vw;
        height: 100vh;
        z-index: 10;

        ::-webkit-scrollbar {
            height: 100% !important;
            width: 7px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background: transparent;

        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #256F68;
            border-radius: 10px;
            width: 2px !important;
            cursor: pointer !important;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #348C83;

        }

        ::-webkit-scrollbar {
            display: block !important;
        }
    }

    .farm-registration-panel-container {
        position: relative;

        .Panel-Header {
            align-items: center;
            color: #C4FFF9;
            text-transform: uppercase;
            padding-inline-start: 1.5rem;
            font-size: 0.8rem;
        }

        .panel-subtitle {
            padding-inline-start: 1.5rem;
            padding-inline-end: 2rem;
            font-size: 0.8rem;
            color: #46AAA0;
            margin-bottom: 1rem;
        }

        .proceed-button {
            width: 40%;
            margin-inline: auto;
        }

        .panel-content-wrapper {
            height: 75vh;
            overflow-y: scroll;
            padding-block: 5px;

            .soil-select {

                .drop-down-selector-container {
                    background-color: #145853;
                    // @extend .neuo;
                    box-shadow: -3px -3px 6px lighten(#145853, 5%), 3px 4px 8px darken(#145853, 4.3%) !important;
                    width: 8rem;
                    padding-block: 15px;
                    margin-block-start: 10px;

                    .triangle {
                        border-bottom: 10px solid #10524D !important;
                        top: 2.5em !important;
                    }

                    .current-choice {
                        white-space: nowrap;
                        color: #73E4CC !important;
                    }

                    .choices-list {
                        background-color: #10524D;
                        box-shadow: 1px 3px 8px #00000026;
                        z-index: 10 !important;
                        top: 3.2em !important;
                        width: 150px !important;
                        height: 190px !important;
                        overflow-y: scroll !important;

                        li:not(.choice-on-focus) {
                            color: #73E4CC;
                        }
                    }

                    &:hover {
                        background: #73E4CC 0% 0% no-repeat padding-box;
                        border: 2px solid #C4FFF9;
                        color: #165F59;

                        .current-choice {
                            color: #165F59 !important;
                        }
                    }
                }

                .expanded {
                    background: #73E4CC 0% 0% no-repeat padding-box !important;
                    border: 2px solid #C4FFF9 !important;
                    color: #165F59 !important;

                    .current-choice {
                        color: #165F59 !important;
                    }
                }
            }

            .basic-info-wrapper {
                padding-inline: 1.5rem;
                margin-top: -5px;

                .edit-button {
                    // @extend .neuo;
                    outline: none;
                    border: none;
                    padding-inline: 0.4rem;
                    padding-block: 0.2rem;
                    min-width: 1.6rem;
                    min-height: 1.6rem;
                    border-radius: 5px;
                    background: #165F59 0% 0% no-repeat padding-box;
                    box-shadow: -3px -3px 6px lighten(#165F59, 5%), 3px 4px 8px darken(#165F59, 4.3%) !important;
                    // box-shadow: -3px 0px 6px #0000001F;
                    position: absolute;
                    inset-inline-end: 5px;

                    &:hover {
                        background: #73E4CC 0% 0% no-repeat padding-box;
                        border: 2px solid #C4FFF9;

                        svg {
                            path {
                                fill: #0A4A45;
                            }
                        }
                    }
                }



                .info-key {
                    color: #C4FFF9;
                    text-transform: uppercase;
                    font-size: 0.8rem;
                }

                .info-value {
                    color: #46AAA0;
                    text-transform: capitalize;
                    font-size: 0.8rem;
                    margin-bottom: 10px;
                    position: relative;
                }



                .input-group {
                    margin-top: 10px;
                }

                .text-area:empty {
                    margin-top: -10px;
                }

                .input-group-prepend {
                    bottom: 12%;
                }

                .line-seperator {
                    width: 100%;
                    border-bottom: 1px solid #46AAA080;
                    opacity: 0.5;
                    margin-block: 20px;
                }

                .description-input {
                    .mb-4 {
                        margin-bottom: 15px !important;
                    }

                    .input-group-text {
                        align-items: flex-end;
                    }
                }

                .description-length {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 0.7rem;
                    color: #46AAA0;
                    margin-block-start: -10px;
                }
            }

            .plot-list-wrapper {
                padding-inline: 2rem;
                padding-block-start: 15px;
                padding-block-end: 25px;
            }
        }


    }
}

@media (max-width: 850px) {

    .farm-registration-map-container .badge-wrapper {
        inset-inline-start: 2rem !important;
        inset-inline-end: inherit !important;
    }

    .mapboxgl-ctrl-geocoder--input {
        height: 40px !important;
    }

    .mapboxgl-ctrl-geocoder--icon-search {
        top: 10px !important;
    }

    .mapboxgl-ctrl-geocoder {
        margin-inline-start: 2rem !important;
        margin-block-start: 5rem !important;
        min-width: 220px !important;
        max-width: 220px !important;
    }

    .farm-registration-wrapper {
        flex-direction: column;
    }

    .farm-registration-map-container {
        width: 100vw !important;
        height: 50% !important;
    }

    .farm-registration-panel-container,
    .farm-registration-panel-container .Panel {
        width: 100vw !important;
        height: 50% !important;
    }

    .farm-registration-panel-container .Panel-Container-View {
        position: absolute;
        height: 100%;
    }

}

// [dir='rtl'] .farm-registration-wrapper {
//     .farm-registration-map-container {
//         .shapes-buttons-container {
//             inset-inline-end: -3rem;

//             button:not(:disabled):hover .animation-tooltip {
//                 inset-inline-start: 2.7rem;
//             }
//         }
//     }

// }

[dir=rtl] .farm-registration-map-container {
    .mapboxgl-ctrl-top-right {
        top: 40%;

        .mapboxgl-ctrl-group {
            overflow: visible;
            box-shadow: none;
            background: transparent;



            .mapbox-gl-draw_ctrl-draw-btn,
            .mapboxgl-ctrl-compass {
                display: none;
            }

            &:first-child {
                position: absolute;
                inset-inline-start: 2rem;

                &:before {
                    content: "أدوات التكبير";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-inline: 5px;
                    background-color: #165F5999;
                    color: #F2FFFE;
                    font-size: 0.8rem;
                    white-space: nowrap;
                    transform: rotate(90deg) translate(2.9rem, -3.5rem);
                }

                .mapboxgl-ctrl-zoom-in,
                .mapboxgl-ctrl-zoom-out {
                    width: 2rem;
                    height: 2rem;
                    margin-bottom: 10px;
                    background: #03403A 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }

                .mapboxgl-ctrl-zoom-in:before {
                    content: "+";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.7rem;
                    color: #73e4cc;
                }

                .mapboxgl-ctrl-zoom-out:before {
                    content: "−";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.7rem;
                    color: #73e4cc;
                }
            }
        }
    }

    .mapboxgl-ctrl-top-left {
        top: 30%;

        .mapboxgl-ctrl-group {
            overflow: visible;
            box-shadow: none;
            background: transparent;

            &:before {
                content: "ادوات الرسم";
                display: flex;
                justify-content: center;
                align-items: center;
                padding-inline: 5px;
                background-color: #165F5999;
                color: #F2FFFE;
                font-size: 0.8rem;
                white-space: nowrap;
                writing-mode: inherit;
                transform: rotate(-90deg) translate(-4.5rem, -0.8rem);
            }

            &:first-child {
                inset-inline-start: inherit;
            }

            // &:after {
            //     content: "Drawing Tools";
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     padding-inline: 5px;
            //     background-color: #165F5999;
            //     color: #F2FFFE;
            //     font-size: 0.8rem;
            //     white-space: nowrap;
            //     // writing-mode: vertical-lr;
            //     transform: rotate(90deg) translate(-5rem, -0.5rem);
            // }

            .mapbox-gl-draw_ctrl-draw-btn,
            .mapboxgl-ctrl-compass {
                display: none;
            }

        }
    }



    .shapes-buttons-container {
        inset-inline-end: -1rem;

        button:not(:disabled):hover:before {
            content: "";
            inset-inline-start: -0.6rem;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid #165F59;
            border-left: inherit !important;
        }

        button:not(:disabled):hover {
            .animation-tooltip {
                inset-inline-start: -12.6rem;
            }

            .triangle {
                inset-inline-start: -0.61rem;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 10px solid #C4FFF9;
                border-left: none !important;
            }
        }

        &:after {
            display: none;
        }


    }
}