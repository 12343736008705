.search-drop-down-list {
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    margin-bottom: 10px;

    .value-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.8em;
        height: 2.5em;
        border: none;
        outline: none;
        cursor: pointer;
        color: #73E4CC;
        text-transform: capitalize;
        background: #003A35 0% 0% no-repeat padding-box;
        box-shadow: -3px -3px 6px #00544c, 3px 4px 8px #002421;
        border-radius: 5px;
        border-style: hidden;

        svg {
            width: 20px;
            height: 20px;
            margin-inline-end: 5px;
        }

        &:hover {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 2px solid #C4FFF9;
            color: #165F59;
            box-shadow: -3px -3px 6px #00544c, 3px 4px 8px #002421;
            border-radius: 5px;
        }
    }

    .dropdown.picker.form-multi-select.form-multi-select-with-cleaner.search-drop-down-list {
        position: relative;

        & div .form-multi-select-search,
        .form-multi-select-selection::before,
        .form-multi-select-selection::after {
            display: none;
        }

        &:has(.dropdown-menu.show) {

            & div .form-multi-select-search,
            .form-multi-select-selection::before,
            .form-multi-select-selection::after {
                display: block;
            }
        }
    }

    .dropdown-menu.show {
        transform: translate3d(0px, 10px, 0px) !important;
        border: none;
        box-shadow: 0px 3px 6px #02312c64 !important;
        background-color: #165F59;
        border-radius: 5px;
        outline: none;
        color: $primary-txt;
        width: 189px;
        text-align: start;
    }

    &:focus {
        outline: none;
    }

    .dropdown-menu::before {
        content: ' ';
        display: block;
        position: absolute;
        top: -9px;
        inset-inline-start: 21%;
        width: 0;
        height: 0;
        background: none;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #165F59;
        z-index: 100;
    }

    // search box
    .form-multi-select-search {
        position: absolute;
        top: 14px;
        background: #165F59;
        border: none;
        z-index: 10000;
        color: $primary-txt;
        padding-inline: 2.3em;
        padding-block: 0.6em;
        padding-block-end: 0.7em;
        text-transform: capitalize;
        width: 189px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $primary-txt;
            opacity: 0.7;
        }
    }

    //search icon
    .form-multi-select-selection::before {
        content: "";
        position: absolute;
        top: 24px;
        inset-inline-start: 11px;
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url('../../assets/icons/svg/Components/search.svg');
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 100001;
        opacity: 0.9;
    }

    // search box border
    .form-multi-select-selection::after {
        content: "";
        width: 141px;
        height: 1px;
        margin: auto;
        background: $primary-txt;
        opacity: 0.7;
        margin-top: 12px;
        position: absolute;
        top: 32px;
        margin-inline-start: 8px;
        z-index: 100001;
    }

    .virtual-scroller.form-multi-select-options {
        height: 136px !important;
    }

    .form-multi-select-option {
        padding-inline: 1em;
        padding-block: 0.3em;
        color: $primary-txt;
        opacity: 0.6;
        cursor: pointer;

        &:is(:hover, .form-multi-selected) {
            border-inline-start: 2px solid $primary-txt;
            opacity: 1;
            color: $primary-txt;

        }

        &:hover {
            text-transform: uppercase;
        }

        &:first-child {
            margin-top: 40px;
        }
    }

    .form-multi-select-options-empty {
        margin-top: 40px;
        padding-inline: 1em;
        padding-block: 0.5em;
        color: $primary-txt;
        opacity: 0.6;
    }
}

[dir='rtl'] .search-drop-down-list {
    .dropdown-menu.show {
        inset: 0px 0px auto 0px !important;
    }
}