.Dashboard_Container{
    .dashboard-intial-row{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: -10px !important;
        background: $main_layer 0% 0% no-repeat padding-box !important;
    }
    .dashboard-small-row{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: -12px !important;
    }
    @media  (max-width: 600px){
        .dashboard-intial-row{
            padding-left: 20px !important;
            padding-right: 20px !important;
            margin-bottom: -10px !important;
            background: $main_layer 0% 0% no-repeat padding-box !important;
        }
        .dashboard-small-row{
            padding-left: 10px !important;
            padding-right: 10px !important;
            margin-bottom: 1px !important;
        }
    }
    .section-margin2{
        margin-bottom: 0.3rem !important;
        margin-top: 0 !important;
    }
    .section-margin3{
        margin-bottom: -0.2rem !important;
        margin-top: 0 !important;
    }
    .dashboard-soil-water{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        padding-inline: 0px;
        width: 100%;
        .card{
            display: flex;
            flex: 1 40%;  
            @media (max-width:1024px) {
                flex: 1 100%;  
            } 
        }
        .heatmap-container {
            overflow: scroll;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            width: 100%;
        }
    }
    .sensor-card{
        .card-body:has(.empty-state-graph){
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            align-self: center;
            width: 64%;
        }
    }

}
[dir="rtl"] .Dashboard_Container{
    .dashboard-intial-row{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: -10px !important;
        background: $main_layer 0% 0% no-repeat padding-box !important;
    }
    .dashboard-small-row{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: -12px !important;
    }
    @media  (max-width: 600px){
        .dashboard-intial-row{
            padding-left: 20px !important;
            padding-right: 20px !important;
            margin-bottom: -10px !important;
            background: $main_layer 0% 0% no-repeat padding-box !important;
        }
        .dashboard-small-row{
            padding-left: 10px !important;
            padding-right: 10px !important;
            margin-bottom: 1px !important;
        }
    }
    .section-margin2{
        margin-bottom: 0.3rem !important;
        margin-top: 0 !important;
    }
    .section-margin3{
        margin-bottom: -0.2rem !important;
        margin-top: 0 !important;
    }

}
.dashboard-issues-scheduler{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding-inline: 5px;
    width: 100%;
    .scheduler{
        display: flex;
        flex: 1 20%; 
        &__row{
            padding-inline: 1.25rem; 
        }
    }
    .issues{
        display: flex;
        flex: 4 20%;  
        &__row{
            margin-inline: 0.2%;
            padding-inline: 1%;
            display: flex;
            justify-content: space-between;
        }
        .issues-per-plot{
            display: flex;
            flex: 2 50%;
        }
        .number-of-issues{
            display: flex;
            flex: 1 20%;
            justify-content: center;
        }
        .number-of-issues__card-container{
            padding-inline: 0.6rem;
        }
        .number-of-issues__card-container--resolved{
            @media (min-width:1110px){ 
                margin-top: 3.5rem;
            }
        }
        .issues-small-cards-container{
            display: flex;
            flex-direction: column;
            background: #053F39 0% 0% no-repeat padding-box;
            border: 1px solid #46AAA0;
            border-radius: 10px;
            width: 22em;
            height : 27em;
            align-items: center;
            justify-content: center;
            align-self: baseline;
            @media (max-width:1100px){ 
                flex-direction: row;
                background: none;
                border: none;
                border-radius: 0;
                height: 12rem;
                width: 100%;
                justify-content: space-around;

            } 
            .warning-danger-cards-container{
                margin-inline: 2%;
            }
            .resolved-card-container{
                margin-inline-end: 2%;
                position: relative;
            }
            .resolved-issues{
                display:flex;
                flex:1;
                flex-direction:column;
                position: absolute;
                background-color: #0D4641B3;
                width: 12em;
                height: 145px;
                inset-inline-end: -10%;
                bottom: -3%;
                border-radius: 3px;
                padding-top: 0.6rem;
                padding-inline-start: 6.4%;
                .reading-date-container {
                    margin-inline-start: 0.9em;
                    margin-block-start: 0;
                    justify-content: flex-start;
                }
                .resolved-issues-title{
                    color: #C4FFF9;
                    margin-inline-start: 0.7em;
                    margin-top:0.2em;
                    text-transform: uppercase;
                    letter-spacing: 0px;
                    font-weight: 600;
                    font-size: 0.8em;           
                }
                .past-date {
                    background-color: transparent; 
                    border: none; 
                    border-radius: none; 
                }
            }
            .card-with-icon-container{
                // width: -webkit-fill-available;
                margin-inline: 3%;
                padding-bottom: 0em;
                border-width: 2px;
                border-inline-start-width: 10px;
                margin-bottom: 1rem;
                .icon-container{
                    width: 2.7em;
                    height: 2.7em;
                    inset-inline-start:-1.7em;
                    top:1.3em
                }
            }
            .content-container{
                justify-content: center;
                display:flex;
                flex-direction: column;
                align-items: center;
                .title{
                    font-family: 'nunito_extraBold';
                    font-size: 3.4em;
                    margin-bottom: -1rem;
                    margin-top: -0.3rem;
                }
            }
            .warning-danger-cards-container{
                display:flex;
                flex:2;
                justify-content:space-evenly;
                margin-inline: 2%;
            }
            .resolved-card-container{
                display:flex;
                flex:1;
                position: relative;
                padding-inline: 0.3rem;
            }
        }
        .cards-list{
            display: flex;
            width: 100%;
            height: 100%;
            flex: 1 1;
            flex-direction: row;
            padding-bottom: 2em;
            justify-content: space-around;
            .card-with-icon-container {
                width: 22em;
                padding-bottom: 1em;
                height : 27em;
                background: #053F39 0% 0% no-repeat padding-box;
                border: 1px solid #46AAA0;
                border-radius: 10px;
                .icon-container{
                    inset-inline-start:9em;
                }
                .content-container{
                    justify-content: center;
                    display: flex;
                    flex-wrap: wrap;
                    border-radius: 5px;
                    margin-top: 2.75em;                  
                    overflow: scroll;
                    height : 89%;
                    align-content: baseline;
                    .content-container-item{
                        margin-bottom: 1rem;
                        width: 90%;
                        display: flex;
                        padding-inline: 5%;
                    }
                    .name-section{
                        display: flex;
                        flex: 4 20%;
                        color : #C4FFF9;
                    }
                    .value-section{
                        display: flex;
                        flex: 1 20%;
                    }
                    .danger-issues{
                        width: 48px;
                        height: 22px;
                        background: #A83737 0% 0% no-repeat padding-box;
                        border: 1px solid #FF8787;
                        border-radius: 15px;
                        opacity: 1;
                        color: #FFBCBC;
                        text-align: center;
                    }
                    .warning-issues{
                        width: 48px;
                        height: 22px;
                        opacity: 1;
                        text-align: center;
                        margin-inline-start: 7px;
                        background: #FDC473 0% 0% no-repeat padding-box;
                        border: 1px solid #A57531;
                        border-radius: 15px;
                        opacity: 1;
                        color: #A57531;
                    }
                }
                .issues-card-footer{
                    background: #0D5049 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    border-start-start-radius: 0;
                    border-start-end-radius: 0;
                    opacity: 1;
                    width: 100%;
                    letter-spacing: 0.74px;
                    color: #C4FFF9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -6px;
                }
            }
        }
        .reading-date{
            margin-inline: auto;
        }
    }
    
}
.dashboard-issues-scheduler > div{
    margin-bottom: 0.5rem;
    width: 100%;
    border: 1px solid;
    border-radius: 0.25rem;
    background-color: #02312d;
    border-color: #02312d;
}
[dir='rtl'] .dashboard-issues-scheduler{
    .issues{
        .issues-small-cards-container{
            .content-container{
                .title{
                    font-family: 'tajawal_extraBold';
                    font-size: 3.5em;
                }
            }
        }
    }
}
[dir='rtl'] {
    .dashboard-small-row{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: -12px !important;
    }
    .dashboard-initial-row{
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-bottom: -12px !important;
  }
}
@media (max-width: 878px) {
    .dashboard-issues-scheduler {
        flex-direction: column !important;
        .scheduler{
            flex: 1 20%; 
        }
        .issues{
            flex: 1 20%; 
        }
    }
}
@media (max-width: 600px) {
    .dashboard-issues-scheduler {
        .issues{
            .issues-per-plot, .issues-per-sensor{
                width: 100%;
            }
            .number-of-issues{
                width: 100%;
            }
            .issues-small-cards-container{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: 27%;
                margin-top: 1rem;
                .warning-danger-cards-container{
                    display: flex;
                    flex: 1;
                    justify-content: space-evenly;
                    margin-inline: 2%;
                }
                .number-of-issues__card-container--resolved{
                    margin-inline-start: 1rem;
                    .resolved-card-container{
                        display: flex;
                        flex: 1;
                        margin-inline-end: 2%;
                        position: relative;
                        margin-inline-start: 1rem;
                        .resolved-issues{
                            width: 107%;
                            height: 131px;
                            right: -1%;
                        }
                    }    
                }               
            }
            &__row{
                margin-inline: 0.1%;
            }

        }
    }
}