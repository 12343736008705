$header-light-bg: $main_layer !default;
$header-light-color: $primary !default;
$header-light-hover-color: $primary !default;

.c-header {
  border: none !important;
  background-color: $header-light-bg !important;
  margin: 0% !important;
  padding: 0% !important;
  height: 2% !important;

  &-nav-items.dropdown.nav-item {
    .c-avatar~.dropdown-menu.show {
      box-shadow: rgb(3 51 45 / 73%) -3px 3px 6px;
      margin: -7px 7px !important;
    }

    &.show {
      .dropdown-menu.show {
        box-shadow: rgb(3 51 45 / 73%) -3px 3px 6px;
        margin: -7px 7px !important;
      }
    }
  }

  &-nav-items:hover {
    background-color: transparent;
  }

}

.c-header-nav li:last-child.nav-item.show .dropdown-menu.show {
  top: -15% !important
}

[dir=ltr] .ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
  margin-top: 0 !important;

}

[dir=rtl].ml-2,
.mx-2 {
  margin-right: -0.5rem !important;
}

.dropdown-menu {
  position: absolute;
}

.c-subheader {
  margin-top: -1px;
  border-top: themes-get-value("subheader-separator");
}

.c-header-nav {

  .c-header-nav-link,
  .c-header-nav-btn {
    color: $primary;

    & :hover {
      color: $primary
    }
  }

  .c-header-nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.heading {
  margin-inline-start: 0.7rem;
  color: $primary-txt !important;

}

[dir=ltr].heading.c-header-nav-link.active {
  color: $primary-txt !important;
  font-family: nunito_extraBold;
  font-size: calc(12px + (12 - 10) * ((100vw - 320px) / (2800 - 320)))
}

.c-header-nav-item {
  margin: 0% !important;
}

.c-header {
  background-color: $main_layer !important;
  border: 0 !important;

  .c-header-nav {
    margin-top: 3px !important;
  }

  #notification {
    width: 30px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: flex;
      width: 22px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 0 50%;
    }

    @extend .neuo;
  }

  .c-header-nav-items:has(#avatar-button) {
    background-color: transparent;
    border-radius: 50%;
    height: 33px;
  }

  .avatar {
    border-radius: 40px;

    #avatar-button {
      color: $primary-txt !important;

      &.avatar-name-wrapper {
        width: 40px;
        height: 34px;
        border-radius: 50%;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .avatar-image {
        // padding: 0.3em 0;
        font-size: 12px;
        letter-spacing: 1px;
        color: #73E4CC;
        outline: none;
        border-color: transparent;
        text-transform: uppercase;
      }

      .arabic-name {
        font-size: 14px;
      }
    }
  }

  .nav-link {
    padding: 0.15rem 0.5rem !important;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }

  .dropdown-header {
    width: 40px !important;
    background-color: $cta-txt !important;
    border: 0px !important;
    margin-left: 10px !important;
    color: $primary !important;
    height: 30px !important;
    min-width: 40px !important;
    font-size: .7em !important;
    padding: 0% !important;
    padding-top: 5px !important;
  }

  .dropdown-menu {
    width: 40px !important;
    min-width: 9rem !important;
    background-color: $cta-txt !important;
    border: 0px !important;
    margin-left: 10px !important;

    &.show {
      display: block !important;
      transform: translate3d(-90px, 54px, 0px) !important;
    }
  }

  .dropdown-item {
    color: $primary !important;
    height: 30px !important;
    min-width: 40px !important;
    font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));

    img {
      padding-right: 5px !important;
      padding-left: 5px !important;
      width: 25px !important;
      height: 25px !important;

      &.img-top {
        display: inline !important;
      }

      &.img-down {
        display: none !important;
      }
    }

    &:hover {
      background: $bubble-selection 0% 0% no-repeat padding-box !important;
      color: $cta-txt !important;
      text-transform: uppercase !important;
      font-family: nunito_bold;

      .img-top {
        display: none !important
      }

      .img-down {
        display: inline !important;
      }
    }
  }
}

[dir=rtl] #notification {
  justify-content: center !important;
}

[dir='rtl'] .d-md-down-none .mr-auto {
  .px-3 {
    .c-header-nav-item {
      padding-right: 0% !important;
    }
  }
}

[dir=rtl] .mr-auto,
.mx-auto {
  margin-right: 0px !important;
}

li:first-child {
  padding-top: 0;
}

[dir=ltr] .radioButton-Langauge {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 20rem;
  box-shadow: inset 0 0 1.2rem $main_layer;
  margin-right: 0.2rem;
}

[dir=rtl] .radioButton-Langauge {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 20rem;
  box-shadow: inset 0 0 1.2rem $main_layer;
  margin-left: 0.2rem;
}

[dir=ltr] .Selected-RadioButton-Langauge {
  height: 50%;
  width: 50%;
  transform: translate(50%, 50%);
  border-radius: 20rem;
}


[dir=rtl].Selected-RadioButton-Langauge {
  height: 50%;
  width: 50%;
  transform: translate(-47%, 50%);
  border-radius: 20rem;
}

.c-avatar {
  width: 24px !important;
}

.c-header.c-header-fixed {
  height: 1.3% !important;
  margin-bottom: 0.6% !important;

}

#notification img {
  padding-top: 4px !important;
}

#ddl-button {
  display: inline;
  float: left;
  width: 16px;
  padding: 0.3em 0;

  &+p {
    display: inline;
    font-size: 12px;
    letter-spacing: 1px;
    padding-inline-start: 0.4em;
    //vertical-align: bottom;
    vertical-align: -webkit-baseline-middle;
  }
}

[dir=rtl] #ddl-button {
  float: right !important;
}

.dropdown-divider {
  display: none;
}

.heading {
  font-size: 0.95em;
  letter-spacing: 0.6px !important;

  @media (min-width:1920px) {
    margin-inline-start: 0.8rem;
  }

  @media (min-width:2800px) {
    margin-inline-start: 1.5rem;
  }

  @media (min-width:3800px) {
    margin-inline-start: 2rem;
  }

  @media (min-width:4100px) {
    margin-inline-start: 2.5rem;
  }
}

[dir='rtl'] {
  .heading {
    font-family: tajawal_regular !important;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)));
  }

  .c-header-nav li:last-child.nav-item.show .dropdown-menu.show {
    left: 95% !important;
    min-width: 7rem !important;
    box-shadow: rgb(3 51 45 / 73%) 3px 3px 6px;
  }

  .dropdown-menu::before {
    left: 57%;
    top: -0.6em;
  }

  .c-header-nav .c-header-nav-link.nav-link .c-avatar+.dropdown-menu.show {
    left: 125% !important;
    box-shadow: rgb(3 51 45 / 73%) 3px 3px 6px;
  }
}